import TechStackScene from './TechStackScene';

const TechStack = () => {
  return (
    <section className="relative h-[90vh] md:min-h-screen bg-black py-0 w-full">
      <div className="absolute inset-0">
        <TechStackScene />
      </div>
      
      <div className="relative z-10 container mx-auto px-4 flex flex-col h-full justify-between">
        <h2 className="text-3xl font-bold text-center pt-20 gradient-text">Our Tech Stack</h2>
        
        <p className="text-center text-sm text-gray-400 pb-6">
           Click and drag to explore
        </p>
      </div>
    </section>
  );
};

export default TechStack;