import { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import { Textarea } from "@/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Progress } from "@/components/ui/progress";
import { Switch } from "@/components/ui/switch";

type TranslationType = {
  title: string;
  subtitle: string;
  step1: string;
  step2: string;
  step3: string;
  step4: string;
  selectServices: string;
  services: {
    web: string;
    ai: string;
    data: string;
  };
  budget: {
    label: string;
    ranges: string[];
  };
  timeline: {
    label: string;
    options: string[];
  };
  contact: {
    fullName: string;
    email: string;
    phone: string;
    company: string;
    preferredContact: string;
  };
  webDetails: {
    type: string;
    types: {
      business: string;
      ecommerce: string;
      portfolio: string;
      blog: string;
      landing: string;
      webapp: string;
      other: string;
    };
    features: string;
    featuresList: {
      design: string;
      responsive: string;
      seo: string;
      ecommerce: string;
      cms: string;
      auth: string;
      forms: string;
      api: string;
    };
    existingUrl: string;
    techStack: string;
  };
  aiDetails: {
    serviceTypes: string;
    types: {
      chatbot: string;
      avatar: string;
      content: string;
      automation: string;
      recommendations: string;
      speech: string;
    };
    integrationNeeds: string;
    expectedOutcome: string;
    dataRequirements: string;
  };
  dataDetails: {
    analysisTypes: string;
    types: {
      visualization: string;
      predictive: string;
      business: string;
      bigData: string;
      realtime: string;
      aiModels: string;
    };
    dataSources: string;
    tools: string;
    challenges: string;
  };
  exampleLinks: string;
  sampleImages: string;
  maxImages: string;
  uploadImages: string;
  limitExceeded: string;
  maxImagesMessage: string;
  uploadSuccess: string;
  uploadSuccessMessage: string;
  uploadError: string;
  uploadErrorMessage: string;
  addLink: string;
  removeLink: string;
  appDetails: string;
  appDetailsPlaceholder: string;
  consent: string;
  previous: string;
  next: string;
  submit: string;
  success: string;
  successMessage: string;
  error: string;
  errorMessage: string;
  contactMethods: {
    email: string;
    phone: string;
    whatsapp: string;
  };
};

const translations: { en: TranslationType; vi: TranslationType } = {
  en: {
    title: "Get a Custom Quote",
    subtitle: "Tell us about your project, and we'll craft a tailored solution that fits your vision and budget.",
    step1: "Step 1: Choose Your Service Category",
    step2: "Step 2: Project Details",
    step3: "Step 3: Project Scope & Budget",
    step4: "Step 4: Contact Information",
    selectServices: "Select the services you need",
    services: {
      web: "Website Development",
      ai: "AI Solutions",
      data: "Data Analytics"
    },
    budget: {
      label: "Estimated Budget Range",
      ranges: [
        "Less than $1,000",
        "$1,000 - $5,000",
        "$5,000 - $10,000",
        "$10,000+"
      ]
    },
    timeline: {
      label: "Expected Timeline",
      options: [
        "ASAP (Less than a month)",
        "1-3 Months",
        "3-6 Months",
        "Flexible / Open to Discussion"
      ]
    },
    contact: {
      fullName: "Full Name",
      email: "Email Address",
      phone: "Phone Number (Optional)",
      company: "Company Name (Optional)",
      preferredContact: "Preferred Contact Method"
    },
    webDetails: {
      type: "Website Type",
      types: {
        business: "Business Website",
        ecommerce: "E-commerce / Online Store",
        portfolio: "Portfolio / Personal Website",
        blog: "Blog / News Site",
        landing: "Landing Page",
        webapp: "Custom Web Application",
        other: "Other"
      },
      features: "Required Features",
      featuresList: {
        design: "Custom UI/UX Design",
        responsive: "Mobile Responsive",
        seo: "SEO Optimization",
        ecommerce: "E-commerce Integration",
        cms: "Content Management",
        auth: "User Authentication",
        forms: "Custom Forms",
        api: "API Integration"
      },
      existingUrl: "Existing Website URL",
      techStack: "Preferred Tech Stack"
    },
    aiDetails: {
      serviceTypes: "AI Service Types",
      types: {
        chatbot: "AI Chatbot / Virtual Assistant",
        avatar: "AI Video Avatar",
        content: "AI Content Generation",
        automation: "AI Automation",
        recommendations: "AI Recommendations",
        speech: "Speech Processing"
      },
      integrationNeeds: "AI Integration Needs",
      expectedOutcome: "Expected Outcome",
      dataRequirements: "Data Requirements"
    },
    dataDetails: {
      analysisTypes: "Analysis Types",
      types: {
        visualization: "Data Visualization",
        predictive: "Predictive Analytics",
        business: "Business Analytics",
        bigData: "Big Data Processing",
        realtime: "Real-time Analytics",
        aiModels: "Custom AI Models"
      },
      dataSources: "Data Sources",
      tools: "Preferred Tools",
      challenges: "Current Challenges"
    },
    exampleLinks: "Example Links",
    sampleImages: "Sample Images",
    maxImages: "Maximum 5 images",
    uploadImages: "Upload screenshots or mockups",
    limitExceeded: "Upload limit exceeded",
    maxImagesMessage: "You can only upload up to 5 images.",
    uploadSuccess: "Image upload successful",
    uploadSuccessMessage: "Your sample images have been uploaded.",
    uploadError: "Upload error",
    uploadErrorMessage: "An error occurred while uploading images. Please try again.",
    addLink: "Add Link",
    removeLink: "Remove",
    appDetails: "Application Details",
    appDetailsPlaceholder: "Describe your application's functionality, features, and goals...",
    consent: "I agree to receive follow-up emails and updates about my request",
    previous: "Previous",
    next: "Next",
    submit: "Submit Request",
    success: "Quote request submitted successfully!",
    successMessage: "We'll get back to you within 24-48 hours.",
    error: "Error submitting request",
    errorMessage: "Please try again later.",
    contactMethods: {
      email: "Email",
      phone: "Phone",
      whatsapp: "WhatsApp"
    }
  },
  vi: {
    title: "Nhận Báo Giá Tùy Chỉnh",
    subtitle: "Hãy cho chúng tôi biết về dự án của bạn, và chúng tôi sẽ tạo ra giải pháp phù hợp với tầm nhìn và ngân sách của bạn.",
    step1: "Bước 1: Chọn Danh Mục Dịch Vụ",
    step2: "Bước 2: Chi Tiết Dự Án",
    step3: "Bước 3: Phạm Vi & Ngân Sách",
    step4: "Bước 4: Thông Tin Liên Hệ",
    selectServices: "Chọn các dịch vụ bạn cần",
    services: {
      web: "Phát Triển Website",
      ai: "Giải Pháp AI",
      data: "Phân Tích Dữ Liệu"
    },
    budget: {
      label: "Phạm Vi Ngân Sách Dự Kiến",
      ranges: [
        "Dưới $1,000",
        "$1,000 - $5,000",
        "$5,000 - $10,000",
        "Trên $10,000"
      ]
    },
    timeline: {
      label: "Thời Gian Dự Kiến",
      options: [
        "Càng sớm càng tốt (Dưới một tháng)",
        "1-3 Tháng",
        "3-6 Tháng",
        "Linh hoạt / Có thể thảo luận"
      ]
    },
    contact: {
      fullName: "Họ và Tên",
      email: "Địa Chỉ Email",
      phone: "Số Điện Thoại (Không bắt buộc)",
      company: "Tên Công Ty (Không bắt buộc)",
      preferredContact: "Phương Thức Liên Hệ Ưu Tiên"
    },
    webDetails: {
      type: "Loại Website",
      types: {
        business: "Website Doanh Nghiệp",
        ecommerce: "Cửa Hàng Trực Tuyến / E-commerce",
        portfolio: "Website Cá Nhân / Portfolio",
        blog: "Blog / Trang Tin Tức",
        landing: "Trang Đích",
        webapp: "Ứng Dụng Web Tùy Chỉnh",
        other: "Khác"
      },
      features: "Tính Năng Cần Thiết",
      featuresList: {
        design: "Thiết Kế UI/UX Độc Đáo",
        responsive: "Tương Thích Di Động",
        seo: "Tối Ưu Hóa SEO",
        ecommerce: "Tích Hợp E-commerce",
        cms: "Quản Lý Nội Dung",
        auth: "Xác Thực Người Dùng",
        forms: "Biểu Mẫu Tùy Chỉnh",
        api: "Tích Hợp API"
      },
      existingUrl: "URL Website Hiện Tại",
      techStack: "Công Nghệ Ưu Tiên"
    },
    aiDetails: {
      serviceTypes: "Loại Dịch Vụ AI",
      types: {
        chatbot: "Chatbot AI / Trợ Lý Ảo",
        avatar: "Avatar AI Video",
        content: "Tạo Nội Dung AI",
        automation: "Tự Động Hóa AI",
        recommendations: "Gợi Ý Bằng AI",
        speech: "Xử Lý Giọng Nói"
      },
      integrationNeeds: "Nhu Cầu Tích Hợp AI",
      expectedOutcome: "Kết Quả Mong Đợi",
      dataRequirements: "Yêu Cầu Dữ Liệu"
    },
    dataDetails: {
      analysisTypes: "Loại Phân Tích",
      types: {
        visualization: "Trực Quan Hóa Dữ Liệu",
        predictive: "Phân Tích Dự Đoán",
        business: "Phân Tích Kinh Doanh",
        bigData: "Xử Lý Dữ Liệu Lớn",
        realtime: "Phân Tích Thời Gian Thực",
        aiModels: "Mô Hình AI Tùy Chỉnh"
      },
      dataSources: "Nguồn Dữ Liệu",
      tools: "Công Cụ Ưu Tiên",
      challenges: "Thách Thức Hiện Tại"
    },
    exampleLinks: "Liên Kết Tham Khảo",
    sampleImages: "Hình Ảnh Mẫu",
    maxImages: "Tối đa 5 hình ảnh",
    uploadImages: "Tải lên ảnh chụp màn hình hoặc bản mẫu",
    limitExceeded: "Đã vượt quá giới hạn tải lên",
    maxImagesMessage: "Bạn chỉ có thể tải lên tối đa 5 hình ảnh.",
    uploadSuccess: "Tải lên hình ảnh thành công",
    uploadSuccessMessage: "Hình ảnh mẫu của bạn đã được tải lên.",
    uploadError: "Lỗi tải lên",
    uploadErrorMessage: "Đã xảy ra lỗi khi tải lên hình ảnh. Vui lòng thử lại.",
    addLink: "Thêm Liên Kết",
    removeLink: "Xóa",
    appDetails: "Chi Tiết Ứng Dụng",
    appDetailsPlaceholder: "Mô tả chức năng, tính năng và mục tiêu của ứng dụng của bạn...",
    consent: "Tôi đồng ý nhận email và cập nhật về yêu cầu của tôi",
    previous: "Trước",
    next: "Tiếp",
    submit: "Gửi Yêu Cầu",
    success: "Đã gửi yêu cầu báo giá thành công!",
    successMessage: "Chúng tôi sẽ phản hồi trong vòng 24-48 giờ.",
    error: "Lỗi khi gửi yêu cầu",
    errorMessage: "Vui lòng thử lại sau.",
    contactMethods: {
      email: "Email",
      phone: "Điện Thoại",
      whatsapp: "WhatsApp"
    }
  }
};

type QuoteRequest = {
  service_category: string[];
  budget_range: string;
  timeline: string;
  full_name: string;
  email: string;
  phone?: string;
  company_name?: string;
  preferred_contact_method?: string;
  website_type?: string;
  website_features?: string[];
  existing_website_url?: string;
  preferred_tech_stack?: string;
  reference_websites?: string[];
  ai_service_types?: string[];
  ai_integration_needs?: string[];
  expected_outcome?: string;
  data_requirements?: string;
  analysis_types?: string[];
  data_sources?: string[];
  preferred_tools?: string[];
  current_challenges?: string;
  additional_comments?: string;
  example_links?: string[];
  sample_images?: string[];
  app_details?: string;
};

const formSchema = z.object({
  service_category: z.array(z.string()).min(1, "Please select at least one service"),
  budget_range: z.string({
    required_error: "Please select a budget range",
  }),
  timeline: z.string({
    required_error: "Please select a timeline",
  }),
  full_name: z.string().min(2, "Name must be at least 2 characters"),
  email: z.string().email("Please enter a valid email address"),
  phone: z.string().optional(),
  company_name: z.string().optional(),
  preferred_contact_method: z.string().optional(),
  website_type: z.string().optional(),
  website_features: z.array(z.string()).optional(),
  existing_website_url: z.string().optional(),
  preferred_tech_stack: z.string().optional(),
  reference_websites: z.array(z.string()).optional(),
  ai_service_types: z.array(z.string()).optional(),
  ai_integration_needs: z.array(z.string()).optional(),
  expected_outcome: z.string().optional(),
  data_requirements: z.string().optional(),
  analysis_types: z.array(z.string()).optional(),
  data_sources: z.array(z.string()).optional(),
  preferred_tools: z.array(z.string()).optional(),
  current_challenges: z.string().optional(),
  additional_comments: z.string().optional(),
  example_links: z.array(z.string().url("Please enter a valid URL")).optional(),
  sample_images: z.array(z.string()).optional(),
  app_details: z.string().optional(),
  consent: z.boolean().refine((val) => val === true, {
    message: "You must agree to receive updates about your request",
  }),
});

type FormData = z.infer<typeof formSchema>;

const Quote = () => {
  const [step, setStep] = useState(1);
  const [language, setLanguage] = useState<"en" | "vi">("en");
  const { toast } = useToast();
  const [uploading, setUploading] = useState(false);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);
  const t = translations[language];

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      service_category: [],
      website_features: [],
      ai_service_types: [],
      ai_integration_needs: [],
      analysis_types: [],
      data_sources: [],
      consent: false,
      example_links: [],
      sample_images: [],
      app_details: "",
    },
  });

  const handleImageUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) return;

    if (uploadedImages.length + files.length > 5) {
      toast({
        variant: "destructive",
        title: t.limitExceeded,
        description: t.maxImagesMessage,
      });
      return;
    }

    setUploading(true);
    const newImages: string[] = [];

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileExt = file.name.split('.').pop();
        const filePath = `${crypto.randomUUID()}.${fileExt}`;

        const { error: uploadError, data } = await supabase.storage
          .from('quote-samples')
          .upload(filePath, file);

        if (uploadError) throw uploadError;

        if (data) {
          const { data: { publicUrl } } = supabase.storage
            .from('quote-samples')
            .getPublicUrl(filePath);
          
          newImages.push(publicUrl);
        }
      }

      setUploadedImages([...uploadedImages, ...newImages]);
      form.setValue('sample_images', [...uploadedImages, ...newImages]);
      
      toast({
        title: t.uploadSuccess,
        description: t.uploadSuccessMessage,
      });
    } catch (error) {
      console.error('Error uploading images:', error);
      toast({
        variant: "destructive",
        title: t.uploadError,
        description: t.uploadErrorMessage,
      });
    } finally {
      setUploading(false);
    }
  };

  const handleAddExampleLink = () => {
    const currentLinks = form.getValues('example_links') || [];
    form.setValue('example_links', [...currentLinks, '']);
  };

  const handleRemoveExampleLink = (index: number) => {
    const currentLinks = form.getValues('example_links') || [];
    form.setValue('example_links', currentLinks.filter((_, i) => i !== index));
  };

  const handleRemoveImage = (imageUrl: string) => {
    setUploadedImages(uploadedImages.filter(url => url !== imageUrl));
    form.setValue('sample_images', uploadedImages.filter(url => url !== imageUrl));
  };

  const onSubmit = async (data: FormData) => {
    try {
      const { consent, ...rest } = data;

      const submissionData: QuoteRequest = {
        service_category: rest.service_category,
        budget_range: rest.budget_range,
        timeline: rest.timeline,
        full_name: rest.full_name,
        email: rest.email,
        phone: rest.phone,
        company_name: rest.company_name,
        preferred_contact_method: rest.preferred_contact_method,
        website_type: rest.website_type,
        website_features: rest.website_features,
        existing_website_url: rest.existing_website_url,
        preferred_tech_stack: rest.preferred_tech_stack,
        reference_websites: rest.reference_websites,
        ai_service_types: rest.ai_service_types,
        ai_integration_needs: rest.ai_integration_needs,
        expected_outcome: rest.expected_outcome,
        data_requirements: rest.data_requirements,
        analysis_types: rest.analysis_types,
        data_sources: rest.data_sources,
        preferred_tools: rest.preferred_tools,
        current_challenges: rest.current_challenges,
        additional_comments: rest.additional_comments,
        example_links: rest.example_links,
        sample_images: rest.sample_images,
        app_details: rest.app_details,
      };

      const { error } = await supabase
        .from('quote_requests')
        .insert(submissionData);

      if (error) throw error;

      toast({
        title: t.success,
        description: t.successMessage,
      });

      form.reset();
      setStep(1);
    } catch (error) {
      console.error('Error submitting quote request:', error);
      toast({
        variant: "destructive",
        title: t.error,
        description: t.errorMessage,
      });
    }
  };

  const nextStep = async () => {
    const fields = getFieldsForStep(step);
    const isValid = await form.trigger(fields);
    if (isValid) setStep(step + 1);
  };

  const previousStep = () => {
    setStep(step - 1);
  };

  const getFieldsForStep = (currentStep: number): Array<keyof FormData> => {
    switch (currentStep) {
      case 1:
        return ['service_category'];
      case 2:
        return form.getValues('service_category').includes('Website Development') 
          ? ['website_type', 'website_features', 'existing_website_url', 'preferred_tech_stack']
          : form.getValues('service_category').includes('AI Solutions')
          ? ['ai_service_types', 'ai_integration_needs', 'expected_outcome', 'data_requirements']
          : ['analysis_types', 'data_sources', 'preferred_tools', 'current_challenges'];
      case 3:
        return ['budget_range', 'timeline', 'additional_comments'];
      case 4:
        return ['full_name', 'email', 'phone', 'company_name', 'preferred_contact_method', 'consent'];
      default:
        return [];
    }
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <main className="flex-grow container mx-auto px-4 py-24 relative">
        <div className="max-w-3xl mx-auto">
          <div className="flex justify-end mb-4">
            <div className="flex items-center space-x-2">
              <span className="text-sm">EN</span>
              <Switch
                checked={language === "vi"}
                onCheckedChange={(checked) => setLanguage(checked ? "vi" : "en")}
              />
              <span className="text-sm">VN</span>
            </div>
          </div>

          <div className="mb-8 text-center">
            <h1 className="text-4xl font-bold mb-4">{t.title}</h1>
            <p className="text-muted-foreground">
              {t.subtitle}
            </p>
          </div>

          <Progress value={step * 25} className="mb-8" />

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
              {step === 1 && (
                <div className="space-y-6">
                  <h2 className="text-2xl font-semibold">{t.step1}</h2>
                  <FormField
                    control={form.control}
                    name="service_category"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.selectServices}</FormLabel>
                        <div className="grid gap-4">
                          {[
                            { en: "Website Development", vi: t.services.web },
                            { en: "AI Solutions", vi: t.services.ai },
                            { en: "Data Analytics", vi: t.services.data }
                          ].map((service) => (
                            <div key={service.en} className="flex items-center space-x-2">
                              <Checkbox
                                checked={field.value?.includes(service.en)}
                                onCheckedChange={(checked) => {
                                  const newValue = checked
                                    ? [...field.value, service.en]
                                    : field.value?.filter((s) => s !== service.en);
                                  field.onChange(newValue);
                                }}
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                {language === "en" ? service.en : service.vi}
                              </label>
                            </div>
                          ))}
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              {step === 2 && (
                <div className="space-y-6">
                  <h2 className="text-2xl font-semibold">{t.step2}</h2>
                  
                  {form.getValues('service_category').includes('Website Development') && (
                    <>
                      <FormField
                        control={form.control}
                        name="website_type"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t.webDetails.type}</FormLabel>
                            <Select onValueChange={field.onChange} defaultValue={field.value}>
                              <FormControl>
                                <SelectTrigger>
                                  <SelectValue placeholder={t.webDetails.type} />
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                {Object.entries(t.webDetails.types).map(([key, value]) => (
                                  <SelectItem key={key} value={key}>
                                    {value}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="website_features"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t.webDetails.features}</FormLabel>
                            <div className="grid grid-cols-2 gap-4">
                              {Object.entries(t.webDetails.featuresList).map(([key, value]) => (
                                <div key={key} className="flex items-center space-x-2">
                                  <Checkbox
                                    checked={field.value?.includes(key)}
                                    onCheckedChange={(checked) => {
                                      const newValue = checked
                                        ? [...field.value || [], key]
                                        : field.value?.filter((f) => f !== key);
                                      field.onChange(newValue);
                                    }}
                                  />
                                  <label className="text-sm font-medium leading-none">
                                    {value}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  )}

                  {form.getValues('service_category').includes('AI Solutions') && (
                    <>
                      <FormField
                        control={form.control}
                        name="ai_service_types"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t.aiDetails.serviceTypes}</FormLabel>
                            <div className="grid gap-4">
                              {Object.entries(t.aiDetails.types).map(([key, value]) => (
                                <div key={key} className="flex items-center space-x-2">
                                  <Checkbox
                                    checked={field.value?.includes(key)}
                                    onCheckedChange={(checked) => {
                                      const newValue = checked
                                        ? [...field.value || [], key]
                                        : field.value?.filter((t) => t !== key);
                                      field.onChange(newValue);
                                    }}
                                  />
                                  <label className="text-sm font-medium leading-none">
                                    {value}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="expected_outcome"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t.aiDetails.expectedOutcome}</FormLabel>
                            <FormControl>
                              <Textarea
                                placeholder={t.aiDetails.expectedOutcome}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  )}

                  {form.getValues('service_category').includes('Data Analytics') && (
                    <>
                      <FormField
                        control={form.control}
                        name="analysis_types"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>{t.dataDetails.analysisTypes}</FormLabel>
                            <div className="grid gap-4">
                              {Object.entries(t.dataDetails.types).map(([key, value]) => (
                                <div key={key} className="flex items-center space-x-2">
                                  <Checkbox
                                    checked={field.value?.includes(key)}
                                    onCheckedChange={(checked) => {
                                      const newValue = checked
                                        ? [...field.value || [], key]
                                        : field.value?.filter((t) => t !== key);
                                      field.onChange(newValue);
                                    }}
                                  />
                                  <label className="text-sm font-medium leading-none">
                                    {value}
                                  </label>
                                </div>
                              ))}
                            </div>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </>
                  )}
                  
                  <div className="space-y-4">
                    <div className="flex items-center justify-between">
                      <FormLabel>{t.exampleLinks}</FormLabel>
                      <Button
                        type="button"
                        variant="outline"
                        size="sm"
                        onClick={handleAddExampleLink}
                      >
                        {t.addLink}
                      </Button>
                    </div>
                    {form.watch('example_links')?.map((_, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <FormField
                          control={form.control}
                          name={`example_links.${index}`}
                          render={({ field }) => (
                            <FormItem className="flex-1">
                              <FormControl>
                                <Input
                                  placeholder="https://example.com"
                                  {...field}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                        <Button
                          type="button"
                          variant="ghost"
                          size="sm"
                          onClick={() => handleRemoveExampleLink(index)}
                          className="text-destructive"
                        >
                          {t.removeLink}
                        </Button>
                      </div>
                    ))}
                  </div>

                  <FormField
                    control={form.control}
                    name="sample_images"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.sampleImages}</FormLabel>
                        <FormDescription>
                          {t.uploadImages}
                        </FormDescription>
                        <div className="space-y-4">
                          <Input
                            type="file"
                            accept="image/*"
                            multiple
                            onChange={handleImageUpload}
                            disabled={uploading || uploadedImages.length >= 5}
                            className="cursor-pointer"
                          />
                          <div className="grid grid-cols-2 gap-4 mt-4">
                            {uploadedImages.map((imageUrl, index) => (
                              <div key={index} className="relative group">
                                <img
                                  src={imageUrl}
                                  alt={`Sample ${index + 1}`}
                                  className="w-full h-40 object-cover rounded-md"
                                />
                                <button
                                  type="button"
                                  onClick={() => handleRemoveImage(imageUrl)}
                                  className="absolute top-2 right-2 bg-red-500 text-white p-1 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                                >
                                  ×
                                </button>
                              </div>
                            ))}
                          </div>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="app_details"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.appDetails}</FormLabel>
                        <FormDescription>
                          {t.uploadImages}
                        </FormDescription>
                        <FormControl>
                          <Textarea
                            placeholder={t.appDetailsPlaceholder}
                            className="min-h-[150px]"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              {step === 3 && (
                <div className="space-y-6">
                  <h2 className="text-2xl font-semibold">{t.step3}</h2>
                  <FormField
                    control={form.control}
                    name="budget_range"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.budget.label}</FormLabel>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={t.budget.label} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {t.budget.ranges.map((range) => (
                              <SelectItem key={range} value={range}>
                                {range}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="timeline"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.timeline.label}</FormLabel>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder={t.timeline.label} />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {t.timeline.options.map((timeline) => (
                              <SelectItem key={timeline} value={timeline}>
                                {timeline}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="additional_comments"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Additional Comments</FormLabel>
                        <FormControl>
                          <Textarea
                            placeholder="Any special requirements or notes"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              {step === 4 && (
                <div className="space-y-6">
                  <h2 className="text-2xl font-semibold">{t.step4}</h2>
                  <FormField
                    control={form.control}
                    name="full_name"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.contact.fullName}</FormLabel>
                        <FormControl>
                          <Input placeholder="John Doe" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.contact.email}</FormLabel>
                        <FormControl>
                          <Input type="email" placeholder="john@example.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="phone"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.contact.phone}</FormLabel>
                        <FormControl>
                          <Input placeholder="+1 234 567 8900" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="preferred_contact_method"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>{t.contact.preferredContact}</FormLabel>
                        <Select onValueChange={field.onChange} defaultValue={field.value}>
                          <FormControl>
                            <SelectTrigger>
                              <SelectValue placeholder="Select contact method" />
                            </SelectTrigger>
                          </FormControl>
                          <SelectContent>
                            {['Email', 'Phone', 'WhatsApp'].map((method) => (
                              <SelectItem key={method} value={method}>
                                {method}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={form.control}
                    name="consent"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <div className="space-y-1 leading-none">
                          <FormLabel>
                            {t.consent}
                          </FormLabel>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              )}

              <div className="flex justify-between pt-4">
                <Button
                  type="button"
                  variant="outline"
                  onClick={previousStep}
                  disabled={step === 1}
                >
                  {t.previous}
                </Button>
                {step < 4 ? (
                  <Button 
                    type="button" 
                    onClick={nextStep}
                    className="bg-blue-600 hover:bg-blue-700"
                  >
                    {t.next}
                  </Button>
                ) : (
                  <Button 
                    type="submit"
                    className="bg-blue-600 hover:bg-blue-700"
                  >
                    {t.submit}
                  </Button>
                )}
              </div>
            </form>
          </Form>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Quote;
