
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

type Language = 'en' | 'vi';
type LanguageContextType = {
  language: Language;
  setLanguage: (lang: Language) => void;
  toggleLanguage: () => void;
  getPathInLanguage: (path: string, targetLang: Language) => string;
};

// Default values that don't require hooks - set to English
const defaultContextValue: LanguageContextType = {
  language: 'en',
  setLanguage: () => {},
  toggleLanguage: () => {},
  getPathInLanguage: () => '',
};

// Create context with a default value
const LanguageContext = createContext<LanguageContextType>(defaultContextValue);

// Create a wrapper component that provides the context value
export const LanguageProvider = ({ children }: { children: ReactNode }) => {
  const [language, setLanguageState] = useState<Language>(() => {
    // Always default to English
    return 'en';
  });

  // Update localStorage when language changes
  const updateLanguage = (newLang: Language) => {
    if (newLang === language) return;
    
    setLanguageState(newLang);
    if (typeof window !== 'undefined') {
      localStorage.setItem('preferredLanguage', newLang);
    }
  };

  // Toggle between available languages
  const toggleLanguage = () => {
    const newLang = language === 'en' ? 'vi' : 'en';
    updateLanguage(newLang);
  };

  // Convert a path to its equivalent in another language
  const getPathInLanguage = (path: string, targetLang: Language): string => {
    // Handle external URLs
    if (path.startsWith('http')) return path;
    
    const pathParts = path.split('/');
    
    // Handle root path
    if (pathParts.length <= 1) {
      return `/${targetLang}`;
    }
    
    const currentLang = pathParts[1];
    
    // If current path already has a language prefix
    if (currentLang === 'en' || currentLang === 'vi') {
      pathParts[1] = targetLang;
      return pathParts.join('/');
    }
    
    // If no language prefix, add it
    return `/${targetLang}${path}`;
  };

  // Create the context value object
  const contextValue: LanguageContextType = {
    language,
    setLanguage: updateLanguage,
    toggleLanguage,
    getPathInLanguage,
  };

  return (
    <LanguageContext.Provider value={contextValue}>
      {children}
    </LanguageContext.Provider>
  );
};

// Custom hook to use the language context
export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
