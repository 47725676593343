
import { Helmet } from "react-helmet-async";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Github, Linkedin, Mail } from "lucide-react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { useLanguage } from "@/contexts/LanguageContext";

const Team = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      title: "Our Team",
      subtitle: "Meet the Experts Behind Mai Tech",
      description:
        "We are a diverse team of developers, designers, and AI specialists committed to delivering innovative solutions.",
      members: [
        {
          name: "John Doe",
          role: "Founder & CEO",
          image: "/photo-1581092795360-fd1ca04f0952",
          description:
            "With over 15 years of experience in tech leadership, John leads our vision for innovation.",
          linkedin: "https://linkedin.com",
          github: "https://github.com",
          email: "john@maitech.com",
        },
        {
          name: "Jane Smith",
          role: "AI Solutions Lead",
          image: "/photo-1581090464777-f3220bbe1b8b",
          description:
            "Jane specializes in machine learning and AI implementation with 8+ years of experience.",
          linkedin: "https://linkedin.com",
          github: "https://github.com",
          email: "jane@maitech.com",
        },
        {
          name: "Mike Johnson",
          role: "Web Development Lead",
          image: "/photo-1485827404703-89b55fcc595e",
          description:
            "Mike brings 10+ years of web development expertise to our projects.",
          linkedin: "https://linkedin.com",
          github: "https://github.com",
          email: "mike@maitech.com",
        },
      ],
    },
    vi: {
      title: "Đội ngũ của chúng tôi",
      subtitle: "Gặp gỡ các chuyên gia tại Mai Tech",
      description:
        "Chúng tôi là một đội ngũ đa dạng gồm các nhà phát triển, nhà thiết kế và chuyên gia AI cam kết mang đến các giải pháp sáng tạo.",
      members: [
        {
          name: "John Doe",
          role: "Người sáng lập & CEO",
          image: "/photo-1581092795360-fd1ca04f0952",
          description:
            "Với hơn 15 năm kinh nghiệm trong lãnh đạo công nghệ, John dẫn dắt tầm nhìn đổi mới của chúng tôi.",
          linkedin: "https://linkedin.com",
          github: "https://github.com",
          email: "john@maitech.com",
        },
        {
          name: "Jane Smith",
          role: "Trưởng bộ phận Giải pháp AI",
          image: "/photo-1581090464777-f3220bbe1b8b",
          description:
            "Jane chuyên về học máy và triển khai AI với hơn 8 năm kinh nghiệm.",
          linkedin: "https://linkedin.com",
          github: "https://github.com",
          email: "jane@maitech.com",
        },
        {
          name: "Mike Johnson",
          role: "Trưởng bộ phận Phát triển Web",
          image: "/photo-1485827404703-89b55fcc595e",
          description:
            "Mike mang đến hơn 10 năm kinh nghiệm phát triển web cho các dự án của chúng tôi.",
          linkedin: "https://linkedin.com",
          github: "https://github.com",
          email: "mike@maitech.com",
        },
      ],
    },
  };

  return (
    <div className="min-h-screen bg-maitech-900">
      <Helmet>
        <title>Our Team | Meet the Experts at Mai Tech</title>
        <meta name="description" content="Meet the diverse team of developers, designers, and AI specialists at Mai Tech committed to delivering innovative solutions for your business." />
        <meta name="keywords" content="tech team, developers, AI specialists, designers, IT experts, technology leaders" />
        <meta property="og:title" content="Meet Our Team | Mai Tech Experts" />
        <meta property="og:description" content="Get to know the experts behind Mai Tech's innovative solutions." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maitech.com/team" />
        <meta property="og:image" content="/lovable-uploads/150790e8-6857-4a82-84eb-1acef2435f81.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://maitech.com/team" />
      </Helmet>
      <Navbar />
      <div className="container mx-auto px-4 pt-20">
        <div className="text-center mb-12 animate-fade-up">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-white">
            {content[language].title}
          </h1>
          <p className="text-xl text-gray-300">{content[language].subtitle}</p>
          <p className="mt-4 text-gray-400 max-w-3xl mx-auto">
            {content[language].description}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {content[language].members.map((member, index) => (
            <Card
              key={index}
              className="bg-maitech-800/50 border-maitech-700 hover:border-primary transition-colors animate-fade-up"
              style={{ animationDelay: `${index * 100}ms` }}
            >
              <CardHeader>
                <div className="w-32 h-32 mx-auto mb-4 rounded-full overflow-hidden">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-full object-cover"
                  />
                </div>
                <CardTitle className="text-xl text-white text-center">
                  {member.name}
                </CardTitle>
                <CardDescription className="text-gray-400 text-center">
                  {member.role}
                </CardDescription>
              </CardHeader>
              <CardContent>
                <p className="text-gray-300 text-center mb-4">
                  {member.description}
                </p>
                <div className="flex justify-center space-x-4">
                  <a
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-primary transition-colors"
                  >
                    <Linkedin className="w-5 h-5" />
                  </a>
                  <a
                    href={member.github}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-400 hover:text-primary transition-colors"
                  >
                    <Github className="w-5 h-5" />
                  </a>
                  <a
                    href={`mailto:${member.email}`}
                    className="text-gray-400 hover:text-primary transition-colors"
                  >
                    <Mail className="w-5 h-5" />
                  </a>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Team;
