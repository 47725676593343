import { useState } from "react";
import { Menu, X } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ThemeToggle } from "@/components/ThemeToggle";
import { useLanguage } from "@/contexts/LanguageContext";
import LanguageSelector from "@/components/LanguageSelector";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { language } = useLanguage();
  const location = useLocation();
  const navigate = useNavigate();

  // Helper function to get language-prefixed path
  const getLangPath = (path: string): string => {
    if (path.startsWith('http')) return path; // External URL
    return `/${language}${path.startsWith('/') ? path : `/${path}`}`;
  };

  const menuItems = [
    { label: "Home", href: "/" },
    { label: "MAI Tech Solutions", href: "/case-studies" },
    { label: "Contact", href: "/contact" },
  ];

  return (
    <nav className="fixed top-0 left-0 right-0 z-[90] bg-background/95 backdrop-blur-sm border-b">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo section */}
          <div className="flex-shrink-0 flex items-center">
            <Link to={getLangPath("/")} className="flex items-center space-x-2">
              <img
                src="/lovable-uploads/ac390313-d263-4eb0-b33e-a3066ffd834f.png"
                alt="Mai Tech Logo"
                className="h-8 w-8 object-contain"
              />
              <span className="text-foreground font-bold text-lg hidden sm:inline">Mai Tech</span>
            </Link>
          </div>
          
          {/* Desktop menu */}
          <div className="hidden md:flex md:items-center md:justify-between flex-grow ml-10">
            <div className="flex items-baseline space-x-2 lg:space-x-4">
              {menuItems.map((item) => (
                <Link
                  key={item.label}
                  to={getLangPath(item.href)}
                  className="text-muted-foreground hover:text-foreground px-2 lg:px-3 py-2 rounded-md text-sm font-medium transition-colors"
                >
                  {item.label}
                </Link>
              ))}
            </div>
            <div className="flex items-center space-x-2 lg:space-x-4">
              <LanguageSelector />
              <ThemeToggle />
            </div>
          </div>
          
          {/* Mobile menu */}
          <div className="md:hidden flex items-center">
            <LanguageSelector />
            <ThemeToggle />
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="ml-2 text-muted-foreground hover:text-foreground focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            >
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile menu dropdown */}
        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              {menuItems.map((item) => (
                <Link
                  key={item.label}
                  to={getLangPath(item.href)}
                  className="text-muted-foreground hover:text-foreground block px-3 py-2 rounded-md text-base font-medium"
                  onClick={() => setIsOpen(false)}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
