// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://gcijnkojvglwwgbxjlsi.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImdjaWpua29qdmdsd3dnYnhqbHNpIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzUxMzAyNDIsImV4cCI6MjA1MDcwNjI0Mn0.GsFGMcIAqMqBN9vsDOaaPhd7YMAWIfl-9FnHgmSClJw";

// Import the supabase client like this:
// import { supabase } from "@/integrations/supabase/client";

export const supabase = createClient<Database>(SUPABASE_URL, SUPABASE_PUBLISHABLE_KEY);