
import { Mail, MapPin, Phone, Facebook, Instagram, Youtube } from "lucide-react";
import { Link } from "react-router-dom";

const Footer = () => {
  const socialLinks = [
    { icon: <Facebook className="w-5 h-5" />, href: "https://www.facebook.com/maitechcorp", label: "Facebook" },
    { icon: <Instagram className="w-5 h-5" />, href: "https://www.instagram.com/maitechcorp/", label: "Instagram" },
    { icon: <Youtube className="w-5 h-5" />, href: "https://tiktok.com/@maitechcorp", label: "TikTok" },
  ];

  return (
    <footer className="bg-maitech-900/95 border-t border-maitech-800 py-6 md:py-8 mt-8 md:mt-16">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8">
          <div>
            <div className="flex items-center space-x-2 mb-4">
              <img
                src="/lovable-uploads/ac390313-d263-4eb0-b33e-a3066ffd834f.png"
                alt="Mai Tech Logo"
                className="h-8 w-auto"
              />
              <h3 className="text-white font-bold text-lg">Mai Tech</h3>
            </div>
            <p className="text-gray-400 mb-4 text-sm md:text-base">
              CÔNG TY TNHH CÔNG NGHỆ - DỊCH VỤ - THƯƠNG MẠI MAI
            </p>
            <p className="text-gray-400 mb-4 text-sm md:text-base">
              Transforming businesses with cutting-edge technology solutions
            </p>
            <div className="flex space-x-4">
              {socialLinks.map((social) => (
                <a
                  key={social.label}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-primary transition-colors"
                  aria-label={social.label}
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
          
          <div>
            <h3 className="text-white font-bold text-lg mb-3 md:mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-400 hover:text-white transition-colors text-sm md:text-base">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/ai-solutions" className="text-gray-400 hover:text-white transition-colors text-sm md:text-base">
                  AI Solutions
                </Link>
              </li>
              <li>
                <Link to="/website-solutions" className="text-gray-400 hover:text-white transition-colors text-sm md:text-base">
                  Website Solutions
                </Link>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className="text-white font-bold text-lg mb-3 md:mb-4">Contact Us</h3>
            <ul className="space-y-2">
              <li className="flex items-start text-gray-400 text-sm md:text-base">
                <MapPin className="w-4 h-4 mr-2 flex-shrink-0 mt-1" />
                <span>Tầng 5, 262/4 Lạc Long Quân, Phường 10, Quận 11, Thành phố Hồ Chí Minh, Việt Nam.</span>
              </li>
              <li className="flex items-center text-gray-400 text-sm md:text-base">
                <Phone className="w-4 h-4 mr-2 flex-shrink-0" />
                0777900721
              </li>
              <li className="flex items-center text-gray-400 text-sm md:text-base">
                <Mail className="w-4 h-4 mr-2 flex-shrink-0" />
                maitechnologies1@gmail.com
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-white font-bold text-lg mb-3 md:mb-4">Follow Us</h3>
            <p className="text-gray-400 mb-4 text-sm md:text-base">
              Stay updated with our latest news and updates on social media.
            </p>
            <div className="flex flex-col space-y-2">
              {socialLinks.map((social) => (
                <a
                  key={social.label}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-primary transition-colors flex items-center text-sm md:text-base"
                >
                  {social.icon}
                  <span className="ml-2">{social.label}</span>
                </a>
              ))}
            </div>
          </div>
        </div>
        
        <div className="border-t border-maitech-800 mt-6 md:mt-8 pt-6 md:pt-8 text-center text-gray-400 text-sm">
          <p>&copy; {new Date().getFullYear()} Mai Tech. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
