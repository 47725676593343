
import { SearchIcon } from "lucide-react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { BlogPost } from "./BlogCard";
import { Link } from "react-router-dom";

interface BlogSidebarProps {
  popularPosts: BlogPost[];
  categories: { name: string; count: number }[];
  tags: string[];
}

const BlogSidebar = ({ popularPosts, categories, tags }: BlogSidebarProps) => {
  return (
    <div className="space-y-6 md:space-y-8">
      {/* Search */}
      <Card className="bg-maitech-800 border-primary/20 hover:border-primary/40 transition-colors">
        <CardHeader className="pb-2 md:pb-3 px-4 py-3 md:px-5 md:py-4">
          <CardTitle className="text-base md:text-lg">Search</CardTitle>
        </CardHeader>
        <CardContent className="px-4 pb-4 md:px-5 md:pb-5">
          <div className="relative">
            <Input 
              placeholder="Search articles..." 
              className="pr-10 bg-maitech-900 border-primary/30 focus:border-primary text-sm"
            />
            <Button size="icon" variant="ghost" className="absolute right-0 top-0 h-full aspect-square text-primary/60 hover:text-primary">
              <SearchIcon className="h-4 w-4" />
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* Popular Posts */}
      <Card className="bg-maitech-800 border-primary/20 hover:border-primary/40 transition-colors">
        <CardHeader className="pb-2 md:pb-3 px-4 py-3 md:px-5 md:py-4">
          <CardTitle className="text-base md:text-lg">Popular Articles</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4 md:space-y-5 px-4 pb-4 md:px-5 md:pb-5">
          {popularPosts.map((post) => (
            <Link key={post.id} to={`/blog/${post.slug}`} className="flex gap-2 md:gap-3 group">
              <div className="flex-shrink-0 w-16 h-16 md:w-20 md:h-20 overflow-hidden rounded">
                <img 
                  src={post.coverImage} 
                  alt={post.title} 
                  className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  loading="lazy"
                />
              </div>
              <div className="flex-1">
                <h4 className="font-medium text-white group-hover:text-primary transition-colors line-clamp-2 text-xs md:text-sm">
                  {post.title}
                </h4>
                <div className="flex items-center gap-2 mt-1 text-xs text-gray-500">
                  <span>{post.date}</span>
                  <span className="w-1 h-1 rounded-full bg-gray-500"></span>
                  <span>{post.readTime}</span>
                </div>
              </div>
            </Link>
          ))}
        </CardContent>
      </Card>

      {/* Categories */}
      <Card className="bg-maitech-800 border-primary/20 hover:border-primary/40 transition-colors">
        <CardHeader className="pb-2 md:pb-3 px-4 py-3 md:px-5 md:py-4">
          <CardTitle className="text-base md:text-lg">Categories</CardTitle>
        </CardHeader>
        <CardContent className="px-4 pb-4 md:px-5 md:pb-5">
          <ul className="space-y-2 md:space-y-3">
            {categories.map((category, index) => (
              <li key={index} className="border-b border-maitech-700 pb-2 last:border-0 last:pb-0">
                <Link 
                  to={`/blog/category/${category.name.toLowerCase().replace(/ /g, '-')}`}
                  className="flex justify-between items-center group"
                >
                  <span className="text-gray-300 group-hover:text-primary transition-colors text-xs md:text-sm">
                    {category.name}
                  </span>
                  <span className="text-xs bg-maitech-900 px-1.5 py-0.5 md:px-2 md:py-1 rounded-full text-gray-400 group-hover:bg-primary/10 group-hover:text-primary transition-colors">
                    {category.count}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </CardContent>
      </Card>

      {/* Tags */}
      <Card className="bg-maitech-800 border-primary/20 hover:border-primary/40 transition-colors">
        <CardHeader className="pb-2 md:pb-3 px-4 py-3 md:px-5 md:py-4">
          <CardTitle className="text-base md:text-lg">Tags</CardTitle>
        </CardHeader>
        <CardContent className="px-4 pb-4 md:px-5 md:pb-5">
          <div className="flex flex-wrap gap-1.5 md:gap-2">
            {tags.map((tag, index) => (
              <Link 
                key={index}
                to={`/blog/tag/${tag.toLowerCase().replace(/ /g, '-')}`}
                className="text-xs px-2 py-1 md:px-3 md:py-1.5 rounded-full bg-maitech-900 text-gray-300 hover:bg-primary/20 hover:text-primary transition-colors"
              >
                {tag}
              </Link>
            ))}
          </div>
        </CardContent>
      </Card>

      {/* Newsletter */}
      <Card className="bg-maitech-800 border-primary/20 hover:border-primary/40 transition-colors overflow-hidden">
        <div className="absolute top-0 right-0 w-16 h-16 md:w-24 md:h-24 opacity-5">
          <svg viewBox="0 0 24 24" fill="currentColor" className="text-primary w-full h-full">
            <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
            <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
          </svg>
        </div>
        <CardHeader className="pb-2 md:pb-3 px-4 py-3 md:px-5 md:py-4">
          <CardTitle className="text-base md:text-lg">Newsletter</CardTitle>
        </CardHeader>
        <CardContent className="px-4 pb-4 md:px-5 md:pb-5">
          <p className="text-xs md:text-sm text-gray-400 mb-3 md:mb-4">
            Subscribe to our newsletter for the latest updates on AI technology and insights.
          </p>
          <Input 
            placeholder="Your email address" 
            className="mb-3 bg-maitech-900 border-primary/30 focus:border-primary text-sm"
          />
          <Button className="w-full bg-primary hover:bg-primary/90 text-sm">Subscribe</Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default BlogSidebar;
