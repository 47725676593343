import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowLeft, Clock, Bell } from "lucide-react";

const ComingSoon = () => {
  return (
    <div className="min-h-screen bg-maitech-900">
      <Navbar />
      <main className="container mx-auto px-4 py-32">
        <div className="max-w-3xl mx-auto text-center space-y-8">
          <div className="flex justify-center mb-8">
            <Clock className="h-24 w-24 text-primary animate-pulse" />
          </div>
          <h1 className="text-4xl md:text-6xl font-bold text-white mb-6 animate-fade-up">
            Coming Soon
          </h1>
          <p className="text-xl text-gray-400 leading-relaxed mb-8 animate-fade-up delay-100">
            We're working hard to bring you something amazing. Our team is putting the finishing touches on this feature to ensure we deliver the best possible experience for you.
          </p>
          <div className="flex flex-col items-center space-y-6 animate-fade-up delay-200">
            <div className="flex items-center justify-center space-x-2 text-primary">
              <Bell className="h-5 w-5" />
              <span>Stay tuned for updates!</span>
            </div>
            <Link to="/">
              <Button variant="outline" className="group">
                <ArrowLeft className="mr-2 h-4 w-4 group-hover:-translate-x-1 transition-transform" />
                Back to Home
              </Button>
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ComingSoon;