import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Hero3D from "@/components/Hero3D";
import Stats from "@/components/Stats";
import VideoShowcase from "@/components/VideoShowcase";
import ChatBot from "@/components/ChatBot";
// import Testimonials from "@/components/Testimonials";
import Faq from "@/components/Faq";
import TrustBadges from "@/components/TrustBadges";
import TechStack from "@/components/TechStack";

const Index = () => {
  useEffect(() => {
    // Add chat widget script
    const script = document.createElement("script");
    script.src = "https://preview--bot-signature-gate.lovable.app/embed.js";
    script.defer = true;
    script.setAttribute("data-bot-id", "3e0019fc-0f31-40fb-ac01-79fd3e6cf687");
    script.setAttribute("data-public-access", "true");
    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-maitech-900 text-foreground w-full">
      <Helmet>
        <title>Mai Tech | AI and Tech Solutions for Modern Businesses</title>
        <meta name="description" content="Mai Tech provides cutting-edge AI integration and professional tech solutions to empower businesses in the digital age." />
        <meta name="keywords" content="AI solutions, tech solutions, digital transformation, business technology, artificial intelligence" />
        <meta property="og:title" content="Mai Tech | AI and Tech Solutions" />
        <meta property="og:description" content="Empowering businesses with cutting-edge AI and tech solutions." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maitech.com" />
        <meta property="og:image" content="/lovable-uploads/7f0e387a-35f5-4aa2-9903-2115158d850e.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Mai Tech | AI and Tech Solutions" />
        <meta name="twitter:description" content="Empowering businesses with cutting-edge AI and tech solutions." />
        <meta name="twitter:image" content="/lovable-uploads/7f0e387a-35f5-4aa2-9903-2115158d850e.png" />
        <link rel="canonical" href="https://maitech.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>
      <Navbar />
      
      {/* Hero section */}
      <section id="hero">
        <Hero3D />
      </section>
      
      {/* Content sections */}
      <div className="relative w-full">
        <img
          src="/lovable-uploads/8aaea022-1f37-419e-b233-09643a330fcd.png"
          alt="Technology Background"
          className="absolute inset-0 w-full h-full object-cover opacity-10 mix-blend-overlay"
        />
        <div className="relative z-10">
          <Stats />
          <TechStack />
          {/* <VideoShowcase /> */}
          {/* Temporarily hiding Testimonials section */}
          {/* <Testimonials /> */}
          {/* <Faq /> */}
          {/* <TrustBadges /> */}
        </div>
      </div>
      <Footer />
      <ChatBot />
    </div>
  );
};

export default Index;
