
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ArrowRight } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

const projects = [
  {
    id: 1,
    title: "AI-Powered Analytics Dashboard",
    summary: "A comprehensive analytics platform leveraging artificial intelligence for data visualization",
    image: "/placeholder.svg",
    category: "AI Solutions",
    slug: "ai-analytics-dashboard"
  },
  {
    id: 2,
    title: "E-commerce Website Redesign",
    summary: "Complete redesign and development of a modern e-commerce platform",
    image: "/placeholder.svg",
    category: "Website Solutions",
    slug: "ecommerce-redesign"
  },
  // Add more projects as needed
];

const Portfolio = () => {
  return (
    <div className="min-h-screen flex flex-col overflow-x-hidden">
      <Helmet>
        <title>Our Portfolio | Innovative Projects by Mai Tech</title>
        <meta name="description" content="Explore Mai Tech's portfolio of successful AI integrations, web development projects, and digital transformation case studies." />
        <meta name="keywords" content="portfolio, case studies, web development projects, AI solutions, digital transformation" />
        <meta property="og:title" content="Mai Tech Portfolio | Successful Projects" />
        <meta property="og:description" content="Discover our successful AI and web development projects and see how we've helped businesses transform." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maitech.com/portfolio" />
        <meta property="og:image" content="/lovable-uploads/150790e8-6857-4a82-84eb-1acef2435f81.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://maitech.com/portfolio" />
      </Helmet>
      <Navbar />
      <main className="flex-grow container mx-auto px-4 py-16 md:py-24 overflow-hidden">
        <h1 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12">Our Portfolio</h1>
        
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          {projects.map((project) => (
            <Link 
              key={project.id} 
              to={`/portfolio/${project.slug}`}
              className="transform transition-transform hover:scale-105"
            >
              <Card className="h-full bg-maitech-800 border-maitech-700">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-40 md:h-48 object-cover rounded-t-lg"
                />
                <CardHeader className="p-4 md:p-5">
                  <CardTitle className="text-lg md:text-xl">{project.title}</CardTitle>
                  <CardDescription className="text-gray-400 text-xs md:text-sm">
                    {project.category}
                  </CardDescription>
                </CardHeader>
                <CardContent className="p-4 md:p-5 pt-0">
                  <p className="text-gray-300 mb-4 text-sm md:text-base">{project.summary}</p>
                  <div className="flex items-center text-primary text-sm">
                    View Case Study <ArrowRight className="ml-2 h-3 w-3 md:h-4 md:w-4" />
                  </div>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Portfolio;
