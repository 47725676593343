
import { Toaster } from "@/components/ui/toaster";
import { Toaster as Sonner } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Index from "./pages/Index";
import AiSolutions from "./pages/AiSolutions";
import WebsiteSolutions from "./pages/WebsiteSolutions";
import DataAnalytics from "./pages/DataAnalytics";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Portfolio from "./pages/Portfolio";
import ProjectDetail from "./pages/ProjectDetail";
import ComingSoon from "./pages/ComingSoon";
import Quote from "./pages/Quote";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import ProductShowcase from "./pages/ProductShowcase";
import CaseStudy from "./pages/CaseStudy";
import CaseStudies from "./pages/CaseStudies";
import { LanguageRedirect } from "./components/LanguageRedirect";

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <BrowserRouter>
        <Routes>
          {/* Root route - auto-detect language and redirect */}
          <Route path="/" element={<LanguageRedirect />} />
          
          {/* English routes */}
          <Route path="/en" element={<Index />} />
          <Route path="/en/ai-solutions" element={<AiSolutions />} />
          <Route path="/en/website-solutions" element={<WebsiteSolutions />} />
          <Route path="/en/data-analytics" element={<DataAnalytics />} />
          <Route path="/en/team" element={<Team />} />
          <Route path="/en/contact" element={<Contact />} />
          <Route path="/en/portfolio" element={<Portfolio />} />
          <Route path="/en/portfolio/:slug" element={<ProjectDetail />} />
          <Route path="/en/coming-soon" element={<ComingSoon />} />
          <Route path="/en/quote" element={<Quote />} />
          <Route path="/en/blog" element={<Blog />} />
          <Route path="/en/blog/:slug" element={<BlogPost />} />
          <Route path="/en/products" element={<ProductShowcase />} />
          <Route path="/en/case-study/:slug" element={<CaseStudy />} />
          <Route path="/en/case-studies" element={<CaseStudies />} />
          
          {/* Vietnamese routes */}
          <Route path="/vi" element={<Index />} />
          <Route path="/vi/ai-solutions" element={<AiSolutions />} />
          <Route path="/vi/website-solutions" element={<WebsiteSolutions />} />
          <Route path="/vi/data-analytics" element={<DataAnalytics />} />
          <Route path="/vi/team" element={<Team />} />
          <Route path="/vi/contact" element={<Contact />} />
          <Route path="/vi/portfolio" element={<Portfolio />} />
          <Route path="/vi/portfolio/:slug" element={<ProjectDetail />} />
          <Route path="/vi/coming-soon" element={<ComingSoon />} />
          <Route path="/vi/quote" element={<Quote />} />
          <Route path="/vi/blog" element={<Blog />} />
          <Route path="/vi/blog/:slug" element={<BlogPost />} />
          <Route path="/vi/products" element={<ProductShowcase />} />
          <Route path="/vi/case-study/:slug" element={<CaseStudy />} />
          <Route path="/vi/case-studies" element={<CaseStudies />} />
          
          {/* Legacy routes - redirect to default language */}
          <Route path="/ai-solutions" element={<Navigate to="/en/ai-solutions" replace />} />
          <Route path="/website-solutions" element={<Navigate to="/en/website-solutions" replace />} />
          <Route path="/data-analytics" element={<Navigate to="/en/data-analytics" replace />} />
          <Route path="/team" element={<Navigate to="/en/team" replace />} />
          <Route path="/contact" element={<Navigate to="/en/contact" replace />} />
          <Route path="/portfolio" element={<Navigate to="/en/portfolio" replace />} />
          <Route path="/portfolio/:slug" element={<Navigate to="/en/portfolio/:slug" replace />} />
          <Route path="/coming-soon" element={<Navigate to="/en/coming-soon" replace />} />
          <Route path="/quote" element={<Navigate to="/en/quote" replace />} />
          <Route path="/blog" element={<Navigate to="/en/blog" replace />} />
          <Route path="/blog/:slug" element={<Navigate to="/en/blog/:slug" replace />} />
          <Route path="/products" element={<Navigate to="/en/products" replace />} />
          <Route path="/case-study/:slug" element={<Navigate to="/en/case-study/:slug" replace />} />
          <Route path="/case-studies" element={<Navigate to="/en/case-studies" replace />} />
          
          {/* Catch-all redirect */}
          <Route path="*" element={<Navigate to="/en" replace />} />
        </Routes>
      </BrowserRouter>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;
