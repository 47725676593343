import { ArrowRight } from "lucide-react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import LanguageAwareLink from "@/components/LanguageAwareLink";
import { Helmet } from "react-helmet-async";

const caseStudies = [
  {
    id: 1,
    title: "Digital Transformation for RiceGo F&B Chain",
    description: "How we helped a leading Vietnamese F&B brand with 10+ locations integrate AI solutions into their customer service and management systems.",
    image: "/lovable-uploads/fc6e8cca-180c-44eb-bdac-0f05fde126bf.png",
    results: [
      "35% increase in customer satisfaction",
      "42% reduction in order processing time",
      "28% boost in operational efficiency"
    ],
    industry: "Food & Beverage",
    slug: "ricego-digital-transformation"
  }
  // Other case studies are temporarily hidden
];

const CaseStudies = () => {
  // Since we only have one industry now, we can simplify this
  const industries = ["All", ...new Set(caseStudies.map(study => study.industry))];
  
  return (
    <div className="min-h-screen bg-maitech-900 text-foreground">
      <Helmet>
        <title>MAI Tech Solutions | MAI Tech</title>
        <meta name="description" content="Explore our technology solutions that have transformed businesses and delivered measurable results." />
        <meta property="og:title" content="MAI Tech Solutions | MAI Tech" />
        <meta property="og:description" content="Explore our technology solutions that have transformed businesses." />
        <meta property="og:image" content="/lovable-uploads/fc6e8cca-180c-44eb-bdac-0f05fde126bf.png" />
        <meta property="og:type" content="website" />
      </Helmet>
      
      <Navbar />
      <div className="pt-16 md:pt-20 pb-12 md:pb-16">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center mb-10 md:mb-16">
            <h1 className="text-3xl md:text-5xl font-bold mb-4 md:mb-6 gradient-text">MAI Tech Solutions</h1>
            <p className="text-base md:text-lg text-gray-400 mb-6 md:mb-8">
              Explore our technology solutions that have transformed businesses and delivered measurable results
            </p>
          </div>

          <div className="grid sm:grid-cols-1 md:grid-cols-1 gap-4 md:gap-8 max-w-4xl mx-auto">
            {caseStudies.map((study) => (
              <Card key={study.id} className="bg-maitech-800 border-primary/20 hover:border-primary transition-colors duration-300 overflow-hidden">
                <LanguageAwareLink to={`/case-study/${study.slug}`} className="no-underline">
                  <div className="h-48 md:h-64 overflow-hidden">
                    <img 
                      src={study.image} 
                      alt={study.title}
                      className="w-full h-full object-cover transition-transform duration-500 hover:scale-105"
                    />
                  </div>
                </LanguageAwareLink>
                <CardContent className="p-4 md:p-6">
                  <div className="text-xs font-semibold text-primary/80 uppercase tracking-wide mb-2">
                    {study.industry}
                  </div>
                  <h3 className="text-lg md:text-xl font-bold text-white mb-2">{study.title}</h3>
                  <p className="text-gray-400 mb-4 text-sm md:text-base">{study.description}</p>
                  
                  <div className="mb-4">
                    <h4 className="text-xs md:text-sm font-semibold text-white mb-2">Key Results:</h4>
                    <ul className="space-y-1">
                      {study.results.map((result, idx) => (
                        <li key={idx} className="text-xs md:text-sm text-gray-400 flex items-start">
                          <span className="text-primary mr-2">•</span>
                          {result}
                        </li>
                      ))}
                    </ul>
                  </div>
                  
                  <LanguageAwareLink to={`/case-study/${study.slug}`}>
                    <Button variant="link" className="text-primary p-0 flex items-center group text-xs md:text-sm">
                      Read full case study
                      <ArrowRight className="ml-2 h-3 w-3 md:h-4 md:w-4 transition-transform group-hover:translate-x-1" />
                    </Button>
                  </LanguageAwareLink>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CaseStudies;
