
import { Link, Twitter, Facebook, Linkedin, Mail } from "lucide-react";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";

interface ShareBlogPostProps {
  title: string;
  url: string;
}

const ShareBlogPost = ({ title, url }: ShareBlogPostProps) => {
  // Encode the title and URL for sharing
  const encodedTitle = encodeURIComponent(title);
  const encodedUrl = encodeURIComponent(url);
  
  // Create social media sharing links
  const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedTitle}&url=${encodedUrl}`;
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`;
  const emailUrl = `mailto:?subject=${encodedTitle}&body=${encodedUrl}`;
  
  // Function to copy the current URL to clipboard
  const copyToClipboard = () => {
    navigator.clipboard.writeText(url);
    toast({
      title: "Link copied!",
      description: "The article URL has been copied to your clipboard.",
      duration: 3000,
    });
  };

  return (
    <div className="flex flex-col items-center sm:items-start">
      <h3 className="text-sm font-medium text-gray-300 mb-3">Share this article</h3>
      <div className="flex space-x-2">
        <a 
          href={twitterUrl} 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="Share on Twitter"
        >
          <Button size="icon" variant="outline" className="rounded-full h-9 w-9 border-primary/20 hover:bg-primary/10 hover:text-primary">
            <Twitter className="h-4 w-4" />
          </Button>
        </a>
        <a 
          href={facebookUrl} 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="Share on Facebook"
        >
          <Button size="icon" variant="outline" className="rounded-full h-9 w-9 border-primary/20 hover:bg-primary/10 hover:text-primary">
            <Facebook className="h-4 w-4" />
          </Button>
        </a>
        <a 
          href={linkedinUrl} 
          target="_blank" 
          rel="noopener noreferrer"
          aria-label="Share on LinkedIn"
        >
          <Button size="icon" variant="outline" className="rounded-full h-9 w-9 border-primary/20 hover:bg-primary/10 hover:text-primary">
            <Linkedin className="h-4 w-4" />
          </Button>
        </a>
        <a 
          href={emailUrl}
          aria-label="Share via Email"
        >
          <Button size="icon" variant="outline" className="rounded-full h-9 w-9 border-primary/20 hover:bg-primary/10 hover:text-primary">
            <Mail className="h-4 w-4" />
          </Button>
        </a>
        <Button 
          size="icon" 
          variant="outline" 
          className="rounded-full h-9 w-9 border-primary/20 hover:bg-primary/10 hover:text-primary"
          onClick={copyToClipboard}
          aria-label="Copy link"
        >
          <Link className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

export default ShareBlogPost;
