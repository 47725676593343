import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { ArrowLeft, Check, Award, FileText, ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import LanguageAwareLink from "@/components/LanguageAwareLink";

const RiceGoCase = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-maitech-900">
      <Helmet>
        <title>Digital Transformation for RiceGo F&B Chain | MAI Tech Case Study</title>
        <meta name="description" content="How MAI Tech helped RiceGo integrate AI solutions into their customer service and management systems, resulting in improved efficiency and satisfaction." />
        <meta property="og:title" content="Digital Transformation for RiceGo F&B Chain | MAI Tech Case Study" />
        <meta property="og:description" content="How we helped a leading Vietnamese F&B brand with 10+ locations integrate AI solutions." />
        <meta property="og:image" content="/lovable-uploads/fc6e8cca-180c-44eb-bdac-0f05fde126bf.png" />
        <meta property="og:type" content="article" />
      </Helmet>

      <Navbar />
      
      <div className="container mx-auto px-4 py-16">
        <div className="mb-8">
          <LanguageAwareLink to="/case-studies" className="text-primary flex items-center hover:underline">
            <ArrowLeft className="h-4 w-4 mr-2" /> Back to MAI Tech Solutions
          </LanguageAwareLink>
        </div>

        <header className="mb-16">
          <div className="flex items-center mb-3">
            <span className="text-sm bg-primary/20 text-primary px-3 py-1 rounded-full">
              Food & Beverage
            </span>
          </div>
          <h1 className="text-4xl md:text-5xl font-bold gradient-text mb-6">Digital Transformation for RiceGo F&B Chain</h1>
          <p className="text-xl text-gray-300 max-w-3xl">
            How we helped a leading Vietnamese F&B brand with 10+ locations integrate AI solutions into their customer service and management systems.
          </p>
        </header>

        <div className="mb-16">
          <img 
            src="/lovable-uploads/fc6e8cca-180c-44eb-bdac-0f05fde126bf.png" 
            alt="RiceGo F&B Chain"
            className="w-full h-auto rounded-lg shadow-xl object-cover max-h-[500px]"
          />
        </div>

        <div className="grid md:grid-cols-3 gap-12 mb-16">
          <div className="bg-maitech-800 p-6 rounded-lg border border-maitech-700">
            <div className="flex items-center mb-4">
              <Award className="h-6 w-6 text-primary mr-3" />
              <h3 className="text-xl font-semibold">Key Results</h3>
            </div>
            <ul className="space-y-3">
              <li className="flex items-start">
                <Check className="text-primary mt-1 mr-2 h-5 w-5 flex-shrink-0" />
                <span className="text-gray-200">35% increase in customer satisfaction scores</span>
              </li>
              <li className="flex items-start">
                <Check className="text-primary mt-1 mr-2 h-5 w-5 flex-shrink-0" />
                <span className="text-gray-200">42% reduction in order processing time</span>
              </li>
              <li className="flex items-start">
                <Check className="text-primary mt-1 mr-2 h-5 w-5 flex-shrink-0" />
                <span className="text-gray-200">28% boost in operational efficiency</span>
              </li>
              <li className="flex items-start">
                <Check className="text-primary mt-1 mr-2 h-5 w-5 flex-shrink-0" />
                <span className="text-gray-200">53% increase in repeat customer visits</span>
              </li>
              <li className="flex items-start">
                <Check className="text-primary mt-1 mr-2 h-5 w-5 flex-shrink-0" />
                <span className="text-gray-200">18% reduction in food waste through better inventory management</span>
              </li>
            </ul>
          </div>

          <div className="bg-maitech-800 p-6 rounded-lg border border-maitech-700">
            <div className="flex items-center mb-4">
              <FileText className="h-6 w-6 text-primary mr-3" />
              <h3 className="text-xl font-semibold">Project Details</h3>
            </div>
            <div className="space-y-3">
              <div>
                <p className="text-gray-400 text-sm">Implementation Time</p>
                <p className="text-white">14 weeks</p>
              </div>
              <div>
                <p className="text-gray-400 text-sm">ROI Period</p>
                <p className="text-white">7 months</p>
              </div>
              <div>
                <p className="text-gray-400 text-sm">Technologies Used</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {["Mobile App Development", "Web Development", "Natural Language Processing", "Machine Learning", "Real-time Analytics"].map((tech, idx) => (
                    <span 
                      key={idx} 
                      className="bg-maitech-700/70 px-2 py-1 rounded-md text-xs text-gray-200"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-maitech-800 p-6 rounded-lg border border-maitech-700">
            <div className="flex items-center mb-4">
              <svg className="h-6 w-6 text-primary mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
              </svg>
              <h3 className="text-xl font-semibold">Client Testimonial</h3>
            </div>
            <blockquote className="text-gray-300 italic mb-4">
              "Mai Tech completely transformed our business. The digital solutions they provided not only improved our operational efficiency but also enhanced the customer experience significantly. The AI integrations have given us insights we never had before, allowing us to make better business decisions."
            </blockquote>
            <div>
              <p className="text-white font-medium">Tran Minh</p>
              <p className="text-gray-400 text-sm">Operations Director</p>
              <p className="text-gray-400 text-sm">RiceGo</p>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-12 mb-16">
          <div>
            <h2 className="text-2xl font-bold mb-6 text-white">The Challenge</h2>
            <div className="prose-article bg-maitech-800/50 backdrop-blur rounded-lg p-6 border border-maitech-700/50">
              <p>
                RiceGo, a popular rice-based food chain with over 10 locations across Saigon, was facing challenges in scaling their operations while maintaining consistent customer experience. Their manual ordering and management systems were becoming inefficient as they expanded, leading to longer wait times, inconsistent service quality, and operational bottlenecks. They needed a comprehensive digital solution that could streamline their operations while enhancing customer engagement across all their locations.
              </p>
            </div>
          </div>
          
          <div>
            <h2 className="text-2xl font-bold mb-6 text-white">Our Solution</h2>
            <div className="prose-article bg-maitech-800/50 backdrop-blur rounded-lg p-6 border border-maitech-700/50">
              <p>
                We developed a complete digital ecosystem for RiceGo that included:
              </p>
              <ul className="list-disc pl-5 space-y-2 mt-3">
                <li>Custom-built mobile app for customers with online ordering, loyalty program, and personalized recommendations</li>
                <li>Modern, responsive website with integrated online ordering system</li>
                <li>AI-powered chatbot for customer service and order assistance</li>
                <li>Centralized management dashboard for real-time monitoring of all locations</li>
                <li>Inventory management system with predictive ordering based on historical data</li>
                <li>Staff scheduling and performance tracking tools</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="mb-16">
          <h2 className="text-2xl font-bold mb-6 text-white">Implementation Gallery</h2>
          <div className="grid md:grid-cols-2 gap-6">
            <div className="bg-maitech-800/50 p-4 rounded-lg border border-maitech-700">
              <img 
                src="/lovable-uploads/adf3f7b6-6781-4473-9c52-beef102acb28.png" 
                alt="RiceGo implementation 1"
                className="w-full h-auto rounded-lg object-cover aspect-video"
              />
            </div>
            <div className="bg-maitech-800/50 p-4 rounded-lg border border-maitech-700">
              <img 
                src="/lovable-uploads/8e04c740-a159-4ab0-9ca5-c3dfe9f93a32.png" 
                alt="RiceGo implementation 2"
                className="w-full h-auto rounded-lg object-cover aspect-video"
              />
            </div>
          </div>
        </div>

        <div className="mb-16">
          <h2 className="text-2xl font-bold mb-6 text-white">Implementation Process</h2>
          <div className="relative">
            <div className="absolute left-4 md:left-1/2 h-full w-0.5 bg-maitech-700 transform -translate-x-1/2"></div>
            
            <div className="space-y-12 relative">
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 md:pr-8 md:text-right mb-4 md:mb-0">
                  <h3 className="text-xl font-semibold text-primary">Discovery & Analysis</h3>
                  <p className="text-gray-300 mt-2">
                    Conducted in-depth analysis of current operations, customer journey, and pain points across all locations.
                  </p>
                </div>
                <div className="flex items-center justify-center md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
                  <div className="w-10 h-10 bg-primary rounded-full flex items-center justify-center text-white font-bold">1</div>
                </div>
                <div className="md:w-1/2 md:pl-8 ml-12 md:ml-0"></div>
              </div>
              
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 md:pr-8 md:text-right mb-4 md:mb-0 ml-12 md:ml-0"></div>
                <div className="flex items-center justify-center md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
                  <div className="w-10 h-10 bg-primary rounded-full flex items-center justify-center text-white font-bold">2</div>
                </div>
                <div className="md:w-1/2 md:pl-8 ml-12 md:ml-0">
                  <h3 className="text-xl font-semibold text-primary">Design & Prototyping</h3>
                  <p className="text-gray-300 mt-2">
                    Created detailed wireframes and interactive prototypes for mobile app, website, and management dashboard.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 md:pr-8 md:text-right mb-4 md:mb-0">
                  <h3 className="text-xl font-semibold text-primary">Development</h3>
                  <p className="text-gray-300 mt-2">
                    Built custom solutions using modern technologies while ensuring all components integrate seamlessly.
                  </p>
                </div>
                <div className="flex items-center justify-center md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
                  <div className="w-10 h-10 bg-primary rounded-full flex items-center justify-center text-white font-bold">3</div>
                </div>
                <div className="md:w-1/2 md:pl-8 ml-12 md:ml-0"></div>
              </div>
              
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 md:pr-8 md:text-right mb-4 md:mb-0 ml-12 md:ml-0"></div>
                <div className="flex items-center justify-center md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
                  <div className="w-10 h-10 bg-primary rounded-full flex items-center justify-center text-white font-bold">4</div>
                </div>
                <div className="md:w-1/2 md:pl-8 ml-12 md:ml-0">
                  <h3 className="text-xl font-semibold text-primary">Testing & Training</h3>
                  <p className="text-gray-300 mt-2">
                    Conducted comprehensive testing and provided staff training to ensure smooth adoption.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row">
                <div className="md:w-1/2 md:pr-8 md:text-right mb-4 md:mb-0">
                  <h3 className="text-xl font-semibold text-primary">Deployment & Optimization</h3>
                  <p className="text-gray-300 mt-2">
                    Rolled out solutions in phases and continuously optimized based on real-world performance data.
                  </p>
                </div>
                <div className="flex items-center justify-center md:absolute md:left-1/2 md:transform md:-translate-x-1/2">
                  <div className="w-10 h-10 bg-primary rounded-full flex items-center justify-center text-white font-bold">5</div>
                </div>
                <div className="md:w-1/2 md:pl-8 ml-12 md:ml-0"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-10 mb-6 flex justify-center">
          <div className="max-w-sm">
            <img 
              src="/lovable-uploads/5d34bcea-a8e6-4105-9526-2cd245fa7342.png"
              alt="RiceGo Logo"
              className="w-full h-auto"
            />
          </div>
        </div>

        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold mb-6 text-white">Ready to Achieve Similar Results?</h2>
          <p className="text-gray-300 mb-8 max-w-2xl mx-auto">
            Let's discuss how our solutions can help your business overcome challenges and drive growth.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <LanguageAwareLink to="/contact">
              <Button>
                Contact Us
              </Button>
            </LanguageAwareLink>
            <LanguageAwareLink to="/products">
              <Button variant="outline" className="border-primary text-primary hover:bg-primary/10">
                Explore Our Products
              </Button>
            </LanguageAwareLink>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default RiceGoCase;
