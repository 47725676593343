
import { Helmet } from "react-helmet-async";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { ArrowRight, Check } from "lucide-react";
import { Link } from "react-router-dom";

const ProductShowcase = () => {
  const products = [
    {
      id: 1,
      name: "Mai Chat Agent",
      description: "Customer service and sales AI Chatbot, integrated in your website and all your socials.",
      features: [
        "24/7 customer service",
        "50% increase in deals closed",
        "Seamless integration with existing platforms",
        "Customizable responses",
        "Multilingual support"
      ],
      image: "/lovable-uploads/641b4760-a0a5-4bf0-be58-6e0206b93682.png",
      demoLink: "https://maibotapp.com",
      caseStudyLink: "/case-study/mai-chat-agent",
    },
    {
      id: 2,
      name: "Mai Bot",
      description: "Ask Mai anything for help/advice, Mai is your personal assistant.",
      features: [
        "Natural language processing",
        "Task automation",
        "Workflow integration",
        "Data analysis capabilities",
        "Custom business logic"
      ],
      image: "/lovable-uploads/26622a4a-02c2-49bb-9e4a-77a4cc74d64a.png",
      demoLink: "https://maibotapp.com/bot",
      caseStudyLink: "/case-study/mai-bot",
    },
    {
      id: 3,
      name: "MaiSupply",
      description: "AI-driven supply chain management solution for optimizing logistics and inventory.",
      features: [
        "Real-time inventory tracking",
        "Predictive demand forecasting",
        "Automated procurement workflows",
        "Supplier relationship management",
        "Logistics optimization"
      ],
      image: "https://images.unsplash.com/photo-1586528116311-ad8dd3c8310d?q=80&w=1000",
      demoLink: "#",
      caseStudyLink: "/case-study/mai-supply",
      icon: "/lovable-uploads/35973d38-e43f-44d1-96cd-e2d27988a54b.png"
    },
    {
      id: 4,
      name: "Mai Bot Socials",
      description: "Automate your social media posting and content generation.",
      features: [
        "AI-powered content creation",
        "Scheduled posting across platforms",
        "Analytics and performance tracking",
        "Content calendar management",
        "Trend analysis and recommendations"
      ],
      image: "https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7?q=80&w=1000",
      demoLink: "#",
      caseStudyLink: "/case-study/mai-bot-socials",
    },
    {
      id: 5,
      name: "Mai Call Agent",
      description: "Customer service and AI call agent for seamless phone support.",
      features: [
        "Natural voice interactions",
        "24/7 customer support",
        "Call routing and management",
        "Multi-language support",
        "Integration with CRM systems"
      ],
      image: "https://images.unsplash.com/photo-1591243315780-978fd00ff9db?q=80&w=1000",
      demoLink: "#",
      caseStudyLink: "/case-study/mai-call-agent",
    },
    {
      id: 6,
      name: "Data Analytics Dashboard",
      description: "Comprehensive data visualization and business intelligence platform.",
      features: [
        "Real-time data processing",
        "Customizable dashboards",
        "Advanced filtering capabilities",
        "Export in multiple formats",
        "User-friendly interface"
      ],
      image: "https://images.unsplash.com/photo-1487058792275-0ad4aaf24ca7",
      demoLink: "#",
      caseStudyLink: "/case-study/data-analytics-dashboard",
    }
  ];

  return (
    <div className="min-h-screen bg-maitech-900">
      <Helmet>
        <title>Our Products | Innovative Solutions by Mai Tech</title>
        <meta name="description" content="Discover Mai Tech's innovative products designed to transform your business operations and drive growth with AI and digital solutions." />
        <meta name="keywords" content="AI chatbot, data analytics dashboard, website builder, business solutions, digital products" />
        <meta property="og:title" content="Mai Tech Products | Business Solutions" />
        <meta property="og:description" content="Innovative solutions designed to transform your business and drive growth." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maitech.com/products" />
        <meta property="og:image" content="/lovable-uploads/641b4760-a0a5-4bf0-be58-6e0206b93682.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://maitech.com/products" />
      </Helmet>
      <Navbar />
      <main className="container mx-auto px-4 py-16 md:py-20">
        <div className="text-center mb-10 md:mb-16">
          <h1 className="text-3xl md:text-5xl font-bold mb-3 md:mb-4 gradient-text">Our Products</h1>
          <p className="text-lg md:text-xl text-gray-300 max-w-3xl mx-auto">
            Innovative solutions designed to transform your business and drive growth
          </p>
        </div>

        <div className="space-y-16 md:space-y-24">
          {products.map((product, index) => (
            <div 
              key={product.id} 
              className={`grid md:grid-cols-2 gap-8 md:gap-12 items-center`}
            >
              <div className={`order-2 ${index % 2 === 1 ? "md:order-1" : "md:order-2"}`}>
                <div className="bg-maitech-800/50 p-4 md:p-8 rounded-lg border border-maitech-700 shadow-xl">
                  <img 
                    src={product.image} 
                    alt={product.name}
                    className="w-full h-auto rounded-lg shadow-lg mb-4 md:mb-6 object-cover aspect-video"
                  />
                </div>
              </div>

              <div className={`order-1 ${index % 2 === 1 ? "md:order-2" : "md:order-1"}`}>
                <h2 className="text-2xl md:text-3xl font-bold mb-3 md:mb-4 text-white">{product.name}</h2>
                <p className="text-gray-300 mb-4 md:mb-6 text-sm md:text-base">{product.description}</p>
                
                <div className="space-y-2 md:space-y-3 mb-6 md:mb-8">
                  {product.features.map((feature, idx) => (
                    <div key={idx} className="flex items-start">
                      <Check className="text-primary mt-1 mr-2 h-4 w-4 md:h-5 md:w-5 flex-shrink-0" />
                      <span className="text-gray-200 text-sm md:text-base">{feature}</span>
                    </div>
                  ))}
                </div>
                
                <div className="flex flex-wrap gap-3 md:gap-4">
                  <a href={product.demoLink} target="_blank" rel="noopener noreferrer">
                    <Button size="sm" className="text-sm md:text-base py-1.5 md:py-2">
                      View Demo <ArrowRight className="ml-2 h-3 w-3 md:h-4 md:w-4" />
                    </Button>
                  </a>
                  
                  <Link to={product.caseStudyLink}>
                    <Button size="sm" variant="outline" className="border-primary text-primary hover:bg-primary/10 text-sm md:text-base py-1.5 md:py-2">
                      Case Study <ArrowRight className="ml-2 h-3 w-3 md:h-4 md:w-4" />
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default ProductShowcase;
