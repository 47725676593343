
import { Link, LinkProps } from "react-router-dom";
import { useLanguage } from "@/contexts/LanguageContext";

interface LanguageAwareLinkProps extends Omit<LinkProps, 'to'> {
  to: string;
  children: React.ReactNode;
}

const LanguageAwareLink = ({ to, children, ...props }: LanguageAwareLinkProps) => {
  const { language } = useLanguage();
  
  // Convert path to language-aware path
  const getLanguagePath = (path: string): string => {
    if (path.startsWith('http')) return path; // External URL
    return `/${language}${path.startsWith('/') ? path : `/${path}`}`;
  };

  return (
    <Link to={getLanguagePath(to)} {...props}>
      {children}
    </Link>
  );
};

export default LanguageAwareLink;
