
import { CalendarDays, User, Clock } from "lucide-react";
import { Link } from "react-router-dom";
import { Card, CardContent } from "@/components/ui/card";

export interface BlogPost {
  id: string;
  slug: string;
  title: string;
  excerpt: string;
  coverImage: string;
  date: string;
  author: {
    name: string;
    avatar?: string;
  };
  tags: string[];
  readTime: string;
}

interface BlogCardProps {
  post: BlogPost;
  featured?: boolean;
}

const BlogCard = ({ post, featured = false }: BlogCardProps) => {
  return (
    <Card className={`group bg-maitech-800 border-primary/20 hover:border-primary transition-all duration-300 overflow-hidden hover:shadow-lg hover:shadow-primary/5 ${featured ? 'md:col-span-2' : ''}`}>
      <div className="relative">
        <Link to={`/blog/${post.slug}`} aria-label={post.title}>
          <div className={`${featured ? 'h-72' : 'h-52'} overflow-hidden`}>
            <img 
              src={post.coverImage} 
              alt={post.title}
              className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
              loading="lazy"
            />
          </div>
        </Link>
        {featured && (
          <div className="absolute top-3 left-3 bg-primary/90 text-white text-xs font-medium px-3 py-1 rounded-full">
            Featured
          </div>
        )}
        <div className="absolute top-3 right-3">
          <span className="bg-maitech-900/80 backdrop-blur-sm text-white text-xs px-2 py-1 rounded-full">
            {post.readTime} read
          </span>
        </div>
      </div>
      <CardContent className="p-6">
        <div className="flex flex-wrap gap-2 mb-3">
          {post.tags.slice(0, 3).map((tag, index) => (
            <Link 
              key={index} 
              to={`/blog/tag/${tag.toLowerCase().replace(/ /g, '-')}`}
              className="text-xs px-2 py-1 rounded-full bg-primary/10 text-primary hover:bg-primary/20 transition-colors"
            >
              {tag}
            </Link>
          ))}
        </div>
        
        <Link to={`/blog/${post.slug}`} className="block group">
          <h3 className="text-xl font-bold text-white mb-2 group-hover:text-primary transition-colors line-clamp-2">
            {post.title}
          </h3>
        </Link>
        
        <p className="text-gray-400 mb-4 line-clamp-3">{post.excerpt}</p>
        
        <div className="flex items-center justify-between text-sm text-gray-500">
          <div className="flex items-center">
            <div className="w-6 h-6 rounded-full overflow-hidden mr-2">
              <img 
                src={post.author.avatar || "/lovable-uploads/placeholder.svg"} 
                alt={post.author.name}
                className="w-full h-full object-cover"
              />
            </div>
            <span>{post.author.name}</span>
          </div>
          
          <div className="flex items-center">
            <CalendarDays className="h-4 w-4 mr-1" />
            <span>{post.date}</span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default BlogCard;
