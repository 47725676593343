
import { Helmet } from "react-helmet-async";
import { Palette, Settings, Monitor, Globe } from "lucide-react";
import { useState } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

const WebsiteSolutions = () => {
  const [language, setLanguage] = useState<"en" | "vi">("en");

  const content = {
    en: {
      title: "Professional, All-in-One Website Design Service",
      subtitle: "The Optimal Solution for Your Brand",
      description:
        "In the digital age, a professional website is not just a communication tool but also the 'face' of your business in the online world. Understanding this, we provide an all-in-one website design service, catering to every need and helping your business stand out in the market.",
      features: [
        {
          icon: <Palette className="h-6 w-6" />,
          title: "Unique and Modern Interface Design",
          description:
            "Every website is custom-designed to be creative and optimized for user experience (UI/UX).",
        },
        {
          icon: <Settings className="h-6 w-6" />,
          title: "SEO-Friendly and Performance Optimized",
          description:
            "All websites we design are SEO-compliant, improving rankings on Google and other search engines.",
        },
        {
          icon: <Monitor className="h-6 w-6" />,
          title: "Advanced Feature Integration",
          description:
            "Responsive websites compatible with all devices, integration of shopping carts, and connection to social media.",
        },
        {
          icon: <Globe className="h-6 w-6" />,
          title: "Long-Term Partnership",
          description:
            "We provide maintenance, upgrades, and regular technical support, ensuring your website always runs smoothly.",
        },
      ],
      workflow: {
        title: "Our Professional Workflow",
        steps: [
          "Requirement Gathering and Detailed Consultation",
          "Demo Interface Design",
          "Development and Feature Integration",
          "Comprehensive Testing and Final Delivery",
          "User Training and Maintenance",
        ],
      },
      packages: {
        title: "Flexible Website Design Pricing",
        description: "We offer various service packages to meet the needs and budgets of different clients:",
        items: ["Basic Package", "Advanced Package", "Custom Design Package"],
      },
    },
    vi: {
      title: "Dịch vụ thiết kế website chuyên nghiệp, trọn gói",
      subtitle: "Giải pháp tối ưu cho thương hiệu của bạn",
      description:
        "Trong thời đại số hóa, một website chuyên nghiệp không chỉ là công cụ giao tiếp với khách hàng mà còn là 'bộ mặt' của doanh nghiệp trên môi trường trực tuyến. Hiểu được điều đó, chúng tôi mang đến dịch vụ thiết kế website trọn gói, đáp ứng mọi nhu cầu và giúp doanh nghiệp của bạn nổi bật trên thị trường.",
      features: [
        {
          icon: <Palette className="h-6 w-6" />,
          title: "Thiết kế giao diện độc đáo và hiện đại",
          description:
            "Mỗi website đều được thiết kế riêng biệt, sáng tạo và tối ưu trải nghiệm người dùng (UI/UX).",
        },
        {
          icon: <Settings className="h-6 w-6" />,
          title: "Chuẩn SEO và tối ưu hóa hiệu suất",
          description:
            "Tất cả các website được thiết kế đều đảm bảo chuẩn SEO, cải thiện thứ hạng trên Google và các công cụ tìm kiếm khác.",
        },
        {
          icon: <Monitor className="h-6 w-6" />,
          title: "Tích hợp tính năng tiên tiến",
          description:
            "Website responsive tương thích với mọi thiết bị, tích hợp giỏ hàng, thanh toán trực tuyến.",
        },
        {
          icon: <Globe className="h-6 w-6" />,
          title: "Đồng hành dài lâu",
          description:
            "Chúng tôi cung cấp bảo trì, nâng cấp và hỗ trợ kỹ thuật định kỳ.",
        },
      ],
      workflow: {
        title: "Quy trình làm việc",
        steps: [
          "Tiếp nhận yêu cầu và tư vấn chi tiết",
          "Thiết kế giao diện demo",
          "Lập trình và phát triển tính năng",
          "Kiểm tra và nghiệm thu",
          "Hướng dẫn sử dụng và bảo trì",
        ],
      },
      packages: {
        title: "Báo giá thiết kế website linh hoạt",
        description: "Chúng tôi cung cấp nhiều gói dịch vụ phù hợp với nhu cầu và ngân sách:",
        items: ["Gói cơ bản", "Gói nâng cao", "Gói thiết kế riêng"],
      },
    },
  };

  return (
    <div className="min-h-screen bg-maitech-900">
      <Helmet>
        <title>Website Solutions | Professional Web Design Services | Mai Tech</title>
        <meta name="description" content="Get professional, all-in-one website design services that optimize your brand's online presence. Modern interfaces, SEO-friendly, responsive designs." />
        <meta name="keywords" content="website design, web development, UI/UX, responsive design, SEO-friendly websites, professional web services" />
        <meta property="og:title" content="Professional Website Solutions | Mai Tech" />
        <meta property="og:description" content="All-in-one website design services to optimize your brand's online presence." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maitech.com/website-solutions" />
        <meta property="og:image" content="/lovable-uploads/150790e8-6857-4a82-84eb-1acef2435f81.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://maitech.com/website-solutions" />
      </Helmet>
      <Navbar />
      <div className="container mx-auto px-4 pt-20">
        <div className="flex justify-end mb-8">
          <Button
            variant="outline"
            onClick={() => setLanguage(language === "en" ? "vi" : "en")}
            className="text-white"
          >
            {language === "en" ? "Switch to Vietnamese" : "Switch to English"}
          </Button>
        </div>

        <div className="text-center mb-12 animate-fade-up">
          <h1 className="text-4xl md:text-5xl font-bold mb-4 text-white">
            {content[language].title}
          </h1>
          <p className="text-xl text-gray-300">{content[language].subtitle}</p>
          <p className="mt-4 text-gray-400 max-w-3xl mx-auto">
            {content[language].description}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12 animate-fade-up">
          {content[language].features.map((feature, index) => (
            <Card
              key={index}
              className="bg-maitech-900/50 border-maitech-700 hover:border-primary transition-colors"
            >
              <CardHeader>
                <div className="h-12 w-12 rounded-lg bg-primary/10 flex items-center justify-center mb-4">
                  {feature.icon}
                </div>
                <CardTitle className="text-white">{feature.title}</CardTitle>
              </CardHeader>
              <CardContent>
                <CardDescription className="text-gray-400">
                  {feature.description}
                </CardDescription>
              </CardContent>
            </Card>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
          <div className="animate-fade-up">
            <h2 className="text-2xl font-bold mb-6 text-white">
              {content[language].workflow.title}
            </h2>
            <div className="space-y-4">
              {content[language].workflow.steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-start space-x-3 text-gray-300"
                >
                  <span className="flex-shrink-0 w-6 h-6 rounded-full bg-primary/20 flex items-center justify-center text-primary">
                    {index + 1}
                  </span>
                  <span>{step}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="animate-fade-up">
            <h2 className="text-2xl font-bold mb-6 text-white">
              {content[language].packages.title}
            </h2>
            <p className="text-gray-300 mb-4">
              {content[language].packages.description}
            </p>
            <ul className="space-y-4">
              {content[language].packages.items.map((item, index) => (
                <li
                  key={index}
                  className="flex items-start space-x-3 text-gray-300"
                >
                  <span className="text-primary">•</span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WebsiteSolutions;
