import * as THREE from 'three';

// Basic Three.js scene setup utility
export const setupThreeScene = (container: HTMLElement): {
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
  renderer: THREE.WebGLRenderer;
  animate: () => void;
  cleanup: () => void;
} => {
  // Create scene
  const scene = new THREE.Scene();
  
  // Create camera
  const camera = new THREE.PerspectiveCamera(
    75, // Field of view
    container.clientWidth / container.clientHeight, // Aspect ratio
    0.1, // Near clipping plane
    1000 // Far clipping plane
  );
  camera.position.z = 5;
  
  // Create renderer
  const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  renderer.setSize(container.clientWidth, container.clientHeight);
  renderer.setPixelRatio(window.devicePixelRatio);
  container.appendChild(renderer.domElement);
  
  // Handle window resize
  const handleResize = () => {
    camera.aspect = container.clientWidth / container.clientHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(container.clientWidth, container.clientHeight);
  };
  
  window.addEventListener('resize', handleResize);
  
  // Animation loop
  let frameId: number | null = null;
  const animate = () => {
    frameId = requestAnimationFrame(animate);
    renderer.render(scene, camera);
  };
  
  // Start animation loop
  animate();
  
  // Cleanup function
  const cleanup = () => {
    if (frameId !== null) {
      cancelAnimationFrame(frameId);
    }
    
    if (container && renderer.domElement && container.contains(renderer.domElement)) {
      try {
        container.removeChild(renderer.domElement);
      } catch (e) {
        console.warn('Error removing renderer DOM element:', e);
      }
    }
    
    window.removeEventListener('resize', handleResize);
    renderer.dispose();
    
    // Dispose all scene objects
    scene.traverse((object) => {
      if (object instanceof THREE.Mesh) {
        if (object.geometry) {
          object.geometry.dispose();
        }
        
        if (object.material) {
          if (Array.isArray(object.material)) {
            object.material.forEach(material => disposeMaterial(material));
          } else {
            disposeMaterial(object.material);
          }
        }
      }
    });
  };
  
  // Helper to dispose materials
  const disposeMaterial = (material: THREE.Material) => {
    material.dispose();
    
    // Dispose textures if present
    if ((material as any).map) (material as any).map.dispose();
    if ((material as any).lightMap) (material as any).lightMap.dispose();
    if ((material as any).bumpMap) (material as any).bumpMap.dispose();
    if ((material as any).normalMap) (material as any).normalMap.dispose();
    if ((material as any).specularMap) (material as any).specularMap.dispose();
    if ((material as any).envMap) (material as any).envMap.dispose();
  };
  
  // Return objects for further customization
  return { scene, camera, renderer, animate, cleanup };
};

// Create a basic animated cube
export const createAnimatedCube = (scene: THREE.Scene): THREE.Mesh => {
  // Create geometry
  const geometry = new THREE.BoxGeometry(1, 1, 1);
  
  // Create material
  const material = new THREE.MeshStandardMaterial({
    color: 0x6366f1, // Primary color
    metalness: 0.5,
    roughness: 0.5,
  });
  
  // Create mesh
  const cube = new THREE.Mesh(geometry, material);
  
  // Add to scene
  scene.add(cube);
  
  // Add animation to render loop
  const animate = () => {
    cube.rotation.x += 0.01;
    cube.rotation.y += 0.01;
    
    requestAnimationFrame(animate);
  };
  
  animate();
  
  return cube;
};

// Add ambient lighting to scene
export const addBasicLighting = (scene: THREE.Scene): void => {
  // Ambient light
  const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
  scene.add(ambientLight);
  
  // Directional light
  const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
  directionalLight.position.set(5, 5, 5);
  scene.add(directionalLight);
}; 