
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

// This would typically come from an API or database
const projectsData = {
  "ai-analytics-dashboard": {
    title: "AI-Powered Analytics Dashboard",
    summary: "A comprehensive analytics platform leveraging artificial intelligence for data visualization and insights generation.",
    challenge: "The client needed a way to process and visualize large amounts of data in real-time, while making it accessible to non-technical users.",
    solution: "We developed a custom AI-powered analytics dashboard that automatically processes data and presents insights in an intuitive interface.",
    images: ["/placeholder.svg", "/placeholder.svg"],
    technologies: ["React", "TensorFlow.js", "D3.js", "Node.js"],
    category: "AI Solutions"
  },
  "ecommerce-redesign": {
    title: "E-commerce Website Redesign",
    summary: "Complete redesign and development of a modern e-commerce platform focused on user experience and conversion optimization.",
    challenge: "The existing platform had poor performance metrics and a high cart abandonment rate due to complicated checkout process.",
    solution: "We rebuilt the platform from scratch with a focus on performance, user experience, and simplified checkout flow.",
    images: ["/placeholder.svg", "/placeholder.svg"],
    technologies: ["React", "Node.js", "PostgreSQL", "Stripe"],
    category: "Website Solutions"
  }
};

const ProjectDetail = () => {
  const { slug } = useParams();
  const project = projectsData[slug as keyof typeof projectsData];

  if (!project) {
    return <div>Project not found</div>;
  }

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet>
        <title>{project.title} | Mai Tech Project Case Study</title>
        <meta name="description" content={project.summary} />
        <meta name="keywords" content={`${project.title}, ${project.category}, case study, project, Mai Tech`} />
        <meta property="og:title" content={`${project.title} | Mai Tech Project`} />
        <meta property="og:description" content={project.summary} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`https://maitech.com/portfolio/${slug}`} />
        <meta property="og:image" content={project.images[0]} />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href={`https://maitech.com/portfolio/${slug}`} />
      </Helmet>
      <Navbar />
      <main className="flex-grow container mx-auto px-4 py-24">
        <article className="max-w-4xl mx-auto">
          <header className="text-center mb-12">
            <h1 className="text-4xl font-bold mb-4">{project.title}</h1>
            <p className="text-primary">{project.category}</p>
          </header>

          <div className="space-y-12">
            {/* Summary Section */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">Project Overview</h2>
              <p className="text-gray-300">{project.summary}</p>
            </section>

            {/* Challenge Section */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">The Challenge</h2>
              <p className="text-gray-300">{project.challenge}</p>
            </section>

            {/* Solution Section */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">Our Solution</h2>
              <p className="text-gray-300">{project.solution}</p>
            </section>

            {/* Project Images */}
            <section className="grid md:grid-cols-2 gap-6">
              {project.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${project.title} screenshot ${index + 1}`}
                  className="rounded-lg w-full h-auto"
                />
              ))}
            </section>

            {/* Technologies Used */}
            <section>
              <h2 className="text-2xl font-semibold mb-4">Technologies Used</h2>
              <div className="flex flex-wrap gap-2">
                {project.technologies.map((tech, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-maitech-700 rounded-full text-sm"
                  >
                    {tech}
                  </span>
                ))}
              </div>
            </section>
          </div>
        </article>
      </main>
      <Footer />
    </div>
  );
};

export default ProjectDetail;
