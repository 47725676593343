
import { Helmet } from "react-helmet-async";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { LineChart, BarChart, PieChart, Database, TrendingUp } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import ChatBot from "@/components/ChatBot";
import { useLanguage } from "@/contexts/LanguageContext";

const DataAnalytics = () => {
  const { language } = useLanguage();

  const content = {
    en: {
      title: "Transform Data into Actionable Insights",
      subtitle: "Unlock the Power of Your Data",
      description: "Unlock the full potential of your business data with our cutting-edge analytics services.",
      services: [
        {
          icon: <LineChart className="h-12 w-12 text-primary" />,
          title: "Data Visualization",
          description: "Interactive dashboards and reports that bring clarity to your data.",
        },
        {
          icon: <TrendingUp className="h-12 w-12 text-primary" />,
          title: "Predictive Modeling",
          description: "Advanced techniques to anticipate future trends and behaviors.",
        },
        {
          icon: <Database className="h-12 w-12 text-primary" />,
          title: "Customized Solutions",
          description: "Analytics tailored to your specific business needs.",
        },
      ],
    },
    vi: {
      title: "Chuyển đổi dữ liệu thành thông tin hữu ích",
      subtitle: "Khai phá sức mạnh dữ liệu của bạn",
      description: "Khai thác tiềm năng tối đa từ dữ liệu kinh doanh với dịch vụ phân tích tiên tiến của chúng tôi.",
      services: [
        {
          icon: <LineChart className="h-12 w-12 text-primary" />,
          title: "Trực quan hóa dữ liệu",
          description: "Bảng điều khiển và báo cáo tương tác giúp hiểu rõ dữ liệu của bạn.",
        },
        {
          icon: <TrendingUp className="h-12 w-12 text-primary" />,
          title: "Mô hình dự đoán",
          description: "Kỹ thuật tiên tiến để dự đoán xu hướng và hành vi trong tương lai.",
        },
        {
          icon: <Database className="h-12 w-12 text-primary" />,
          title: "Giải pháp tùy chỉnh",
          description: "Phân tích được điều chỉnh theo nhu cầu cụ thể của doanh nghiệp.",
        },
      ],
    },
  };

  return (
    <div className="min-h-screen bg-background">
      <Helmet>
        <title>Data Analytics Solutions | Transform Your Business Data | Mai Tech</title>
        <meta name="description" content="Unlock the full potential of your business data with our cutting-edge analytics services. From visualization to predictive modeling." />
        <meta name="keywords" content="data analytics, business intelligence, data visualization, predictive modeling, customized analytics solutions" />
        <meta property="og:title" content="Data Analytics Solutions | Mai Tech" />
        <meta property="og:description" content="Unlock the power of your data with our cutting-edge analytics services." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maitech.com/data-analytics" />
        <meta property="og:image" content="/lovable-uploads/150790e8-6857-4a82-84eb-1acef2435f81.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://maitech.com/data-analytics" />
      </Helmet>
      <Navbar />
      
      <main className="pt-20">
        <section className="py-20 bg-maitech-900">
          <div className="container mx-auto px-4">
            <div className="text-center max-w-3xl mx-auto">
              <h1 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                {content[language].title}
              </h1>
              <p className="text-xl text-gray-300 mb-8">
                {content[language].subtitle}
              </p>
              <p className="text-gray-400">
                {content[language].description}
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-16">
              {content[language].services.map((service, index) => (
                <Card key={index} className="bg-maitech-900 border-maitech-800 hover:border-primary transition-colors">
                  <CardHeader>
                    <div className="mb-4">{service.icon}</div>
                    <CardTitle className="text-white">{service.title}</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <CardDescription className="text-gray-400">
                      {service.description}
                    </CardDescription>
                  </CardContent>
                </Card>
              ))}
            </div>
          </div>
        </section>
      </main>

      <ChatBot />
      <Footer />
    </div>
  );
};

export default DataAnalytics;
