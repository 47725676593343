
import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import App from './App.tsx'
import './index.css'
import './App.css'
import { ThemeProvider } from './components/ThemeProvider.tsx'
import { LanguageProvider } from './contexts/LanguageContext.tsx'
import { Toaster } from './components/ui/toaster.tsx'
import { Toaster as Sonner } from './components/ui/sonner.tsx'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider>
        <LanguageProvider>
          <App />
          <Toaster />
          <Sonner />
        </LanguageProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
)
