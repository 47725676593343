import { useEffect, useRef, useState } from 'react';
import { ArrowRight, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import * as THREE from 'three';
import { setupThreeScene, addBasicLighting } from '@/lib/threeUtils';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { useIsMobile } from '@/hooks/use-mobile';


const isMobile = window.innerWidth < 768;
// Product data
const products = [
  {
    id: 'mai-chat',
    name: 'Mai Chat Agent',
    description: 'Intelligent AI chatbot for customer engagement and support.',
    color: 0x3b82f6, // Blue
    position: new THREE.Vector3(5, 0, 0), // Position for orbit
    orbitRadius: isMobile ? 1.5 : 4.5, // Distance from center - DECREASED for mobile
    orbitSpeed: 0.2, // Speed of orbit
    link: 'https://maichatagent.com', // External URL for Mai Chat Agent
    logo: '/logo/maichatagentlogo.png', // Using exact format requested
    fallbackGeometry: new THREE.DodecahedronGeometry(1, 2),
    externalLink: true // Flag to indicate external link
  },
  {
    id: 'mai-socials',
    name: 'Mai Socials Agent',
    description: 'Automated social media management and content creation.',
    color: 0x8b5cf6, // Purple
    position: new THREE.Vector3(0, 4, 0), // Position for orbit
    orbitRadius: isMobile ? 1.5 : 5.5, // Distance from center - DECREASED for mobile
    orbitSpeed: 0.3, // Speed of orbit
    link: '/products/mai-socials-agent',
    logo: '/logo/maisocials.png', // Updated Maibot logo
    fallbackGeometry: new THREE.OctahedronGeometry(1, 2),
    externalLink: false
  },
  {
    id: 'mai-tech',
    name: 'Mai Tech Solutions',
    description: 'Custom technology solutions for business growth.',
    color: 0x10b981, // Green
    position: new THREE.Vector3(-5, -2, 0), // Position for orbit
    orbitRadius: isMobile ? 1.5 : 4.5, // Distance from center - DECREASED for mobile
    orbitSpeed: 0.15, // Speed of orbit
    link: '/case-studies', // Link points to case studies page
    logo: '/logo/maitechsolutions.png', // Mai Tech logo
    fallbackGeometry: new THREE.IcosahedronGeometry(1, 2),
    externalLink: false
  }
];

const Hero3D = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const controlsRef = useRef<OrbitControls | null>(null);
  const productMeshesRef = useRef<THREE.Mesh[]>([]);
  const frameIdRef = useRef<number | null>(null);
  const composerRef = useRef<EffectComposer | null>(null);
  const orbitTimeRef = useRef<{ time: number }>({ time: 0 });
  const spritesRef = useRef<THREE.Sprite[]>([]);
  
  const [selectedProduct, setSelectedProduct] = useState<typeof products[0] | null>(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const isMobile = useIsMobile();

  // Initialize the 3D scene
  useEffect(() => {
    if (!containerRef.current) return;

    // Scene setup
    const scene = new THREE.Scene();
    sceneRef.current = scene;
    // Set to pure black for better contrast with the logos
    scene.background = new THREE.Color(0x000000);

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      60,
      containerRef.current.clientWidth / containerRef.current.clientHeight,
      0.1,
      1000
    );
    cameraRef.current = camera;
    camera.position.z = 12; // Increased to match tech stack

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true,
      alpha: true 
    });
    rendererRef.current = renderer;
    renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    // Use basic tone mapping to preserve original colors
    renderer.toneMapping = THREE.NoToneMapping;
    renderer.toneMappingExposure = 1.0;
    containerRef.current.appendChild(renderer.domElement);

    // Post-processing setup - no bloom or effects
    const composer = new EffectComposer(renderer);
    composerRef.current = composer;
    
    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass);
    
    // Removed bloom pass entirely to keep original image appearance

    // Controls setup
    const controls = new OrbitControls(camera, renderer.domElement);
    controlsRef.current = controls;
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.rotateSpeed = 0.3;
    controls.minDistance = 5;
    controls.maxDistance = 15;
    controls.enablePan = false;
    // Revert stars rotation back to normal
    controls.autoRotate = true;
    controls.autoRotateSpeed = 0.2; // Back to normal speed for stars
    
    // Adjust touch controls for better mobile experience
    controls.touches = {
      ONE: THREE.TOUCH.ROTATE,
      TWO: THREE.TOUCH.DOLLY_ROTATE
    };
    
    // Disable camera momentum to fix scrolling issues on mobile
    controls.enableDamping = window.innerWidth > 768; // Only enable damping on desktop

    // Add lighting
    addBasicLighting(scene);
    
    // Add a softer directional light for uniform illumination without affecting colors
    const mainLight = new THREE.DirectionalLight(0xffffff, 0.5);
    mainLight.position.set(5, 5, 7);
    scene.add(mainLight);
    
    // Add ambient light for better visibility of original colors
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);

    // Create starry background (make it match the tech stack's style)
    createStarryBackground(scene);

    // Create product objects
    createProductObjects(scene, orbitTimeRef);

    // Animate on load
    setTimeout(() => {
      setIsLoaded(true);
      
      // Set camera immediately to final position instead of animating
      camera.position.z = 12;
      
    }, 500);

    // Handle window resize
    const handleResize = () => {
      if (!containerRef.current || !renderer || !camera || !composer) return;
      
      const width = containerRef.current.clientWidth;
      const height = containerRef.current.clientHeight;
      
      camera.aspect = width / height;
      camera.updateProjectionMatrix();
      
      renderer.setSize(width, height);
      composer.setSize(width, height);
      
      // Update camera distance based on screen size
      if (window.innerWidth <= 768) {
        // Move camera further back on mobile to show more of the scene
        camera.position.z = Math.max(camera.position.z, 15);
        
        // Adjust controls for mobile
        if (controlsRef.current) {
          controlsRef.current.enableDamping = false;
          controlsRef.current.rotateSpeed = 0.7; // Faster rotation on mobile
        }
      } else {
        // Reset to default position on desktop if needed
        if (camera.position.z > 12) {
          camera.position.z = 12;
        }
        
        // Adjust controls for desktop
        if (controlsRef.current) {
          controlsRef.current.enableDamping = true;
          controlsRef.current.rotateSpeed = 0.5;
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Animation loop
    const animate = () => {
      frameIdRef.current = requestAnimationFrame(animate);

      // Update controls
      if (controlsRef.current) {
        controlsRef.current.update();
      }

      // Render scene with post-processing
      if (composerRef.current) {
        composerRef.current.render();
      }
    };

    animate();

    // Cleanup
    return () => {
      if (frameIdRef.current) {
        cancelAnimationFrame(frameIdRef.current);
        frameIdRef.current = null;
      }

      if (rendererRef.current && containerRef.current) {
        if (containerRef.current.contains(rendererRef.current.domElement)) {
          try {
            containerRef.current.removeChild(rendererRef.current.domElement);
          } catch (e) {
            console.warn('Error removing renderer DOM element:', e);
          }
        }
        
        // Dispose renderer
        rendererRef.current.dispose();
        rendererRef.current = null;
      }

      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
      
      // Clean up composer
      composerRef.current = null;
    };
  }, []);

  // Create sky-like starry background with white stars
  const createStarryBackground = (scene: THREE.Scene) => {
    // Create stars - now brighter white, matching tech stack
    const starGeometry = new THREE.BufferGeometry();
    const starMaterial = new THREE.PointsMaterial({
      color: 0xffffff,
      size: 0.03, // Match tech stack size
      transparent: true,
      opacity: 0.8, // Match tech stack opacity
    });

    const starVertices = [];
    const starCount = 2000; // Match tech stack count
    const starDistance = 40; // Match tech stack distance

    for (let i = 0; i < starCount; i++) {
      const x = (Math.random() - 0.5) * starDistance;
      const y = (Math.random() - 0.5) * starDistance;
      const z = (Math.random() - 0.5) * starDistance;
      starVertices.push(x, y, z);
    }

    starGeometry.setAttribute(
      'position',
      new THREE.Float32BufferAttribute(starVertices, 3)
    );

    const stars = new THREE.Points(starGeometry, starMaterial);
    scene.add(stars);
  };

  // Create product objects as floating logos orbiting the central logo
  const createProductObjects = (scene: THREE.Scene, orbitRef: React.RefObject<{ time: number }>) => {
    const meshes: THREE.Mesh[] = [];
    const textureLoader = new THREE.TextureLoader();
    const spritesLocalRef: THREE.Sprite[] = [];
    
    // Check if on mobile
    const isMobileDevice = window.innerWidth < 768;
    
    // Sprite scale factors
    const centerSpriteScale = isMobileDevice ? 1.5 : 3;
    const orbitingSpriteScale = isMobileDevice ? 2.1 : 4.2;
    
    // Create central Mai Tech logo as the sun
    textureLoader.load(
      '/lovable-uploads/04b305ca-bbd2-4164-ac92-c50eec955c72.png',
      (texture) => {
        // Create a group to hold the logo and text together as one unit
        const centerGroup = new THREE.Group();
        centerGroup.position.set(0, 0, 0);
        scene.add(centerGroup);
        
        // Create sprite material with the logo
        const spriteMaterial = new THREE.SpriteMaterial({ 
          map: texture,
          transparent: true,
          alphaTest: 0.1,
        });
        
        const centerSprite = new THREE.Sprite(spriteMaterial);
        // Adjust size based on device
        centerSprite.scale.set(centerSpriteScale, centerSpriteScale, centerSpriteScale);
        centerSprite.position.set(0, 0, 0);
        
        // Add the logo to the group
        centerGroup.add(centerSprite);
        
        // Very subtle central light that won't affect original colors
        const sunLight = new THREE.PointLight(0xffffff, 0.3, 15); 
        sunLight.position.set(0, 0, 0);
        scene.add(sunLight);
      }
    );
    
    // Create orbiting product logos
    products.forEach((product) => {
      // Create logo as sprite only
      try {
        textureLoader.load(
          product.logo,
          (texture) => {
            // Create sprite material with the logo
            const spriteMaterial = new THREE.SpriteMaterial({ 
              map: texture,
              transparent: true,
              alphaTest: 0.1,
              opacity: 1.0, // Increased to full opacity for better visibility
            });
            
            const sprite = new THREE.Sprite(spriteMaterial);
            // Adjust size based on device
            sprite.scale.set(orbitingSpriteScale, orbitingSpriteScale, orbitingSpriteScale);
            sprite.position.copy(product.position);
            
            sprite.userData = { 
              isLogo: true,
              productId: product.id,
              orbitRadius: product.orbitRadius,
              orbitSpeed: product.orbitSpeed * 0.25, // Even slower - only 15% of original speed
              initialAngle: Math.random() * Math.PI * 2 // Random starting position
            };
            
            scene.add(sprite);
            spritesLocalRef.push(sprite);
            
            // Add point light matching product color with very low intensity to just highlight
            const pointLight = new THREE.PointLight(product.color, 0, 0); // Further reduced intensity for less bloom
            pointLight.position.copy(product.position);
            scene.add(pointLight);
            
            // Store light for animation
            sprite.userData.light = pointLight;
          },
          undefined,
          (error) => {
            console.warn('Error loading logo texture:', error);
            
            // Add fallback text label
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (ctx) {
              canvas.width = 256;
              canvas.height = 256;
              ctx.fillStyle = '#FFFFFF';
              ctx.font = 'bold 32px Arial';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(product.name, 128, 128);
              
              const texture = new THREE.CanvasTexture(canvas);
              const spriteMaterial = new THREE.SpriteMaterial({ 
                map: texture,
                transparent: true,
                opacity: 1 // Slightly reduce opacity
              });
              
              const sprite = new THREE.Sprite(spriteMaterial);
              sprite.scale.set(0, 0, 0);
              sprite.position.copy(product.position);
              sprite.userData = { 
                productId: product.id,
                orbitRadius: product.orbitRadius,
                orbitSpeed: product.orbitSpeed,
                initialAngle: Math.random() * Math.PI * 2
              };
              
              scene.add(sprite);
              spritesLocalRef.push(sprite);
            }
          }
        );
      } catch (error) {
        console.warn('Error creating logo:', error);
      }
    });

    // Store meshes for cleanup
    productMeshesRef.current = meshes;
    
    // Store sprites reference for resize handling
    spritesRef.current = spritesLocalRef;
    
    // Add orbit and rotation animation to sprites
    const animate = () => {
      // Update orbit time
      if (orbitRef.current) {
        orbitRef.current.time += 0.001; // Keep this slow for product orbits
      }
      
      if (spritesRef.current) {
        spritesRef.current.forEach(sprite => {
          if (sprite.userData.orbitRadius) {
            const radius = sprite.userData.orbitRadius;
            const speed = sprite.userData.orbitSpeed;
            const initialAngle = sprite.userData.initialAngle;
            
            // Calculate orbit position with safe access to orbitRef
            const angle = initialAngle + (orbitRef.current?.time || 0) * speed;
            const x = Math.cos(angle) * radius;
            const y = Math.sin(angle) * radius;
            
            // Update sprite position
            sprite.position.set(x, y, 0);
            
            // Update light position if attached
            if (sprite.userData.light) {
              sprite.userData.light.position.set(x, y, 0);
            }
            
            // Make sprites barely rotate - extremely slow rotation
            sprite.rotation.z += 0.0003; // Extremely slow rotation
          }
        });
      }
      
      requestAnimationFrame(animate);
    };
    
    animate();
  };
  
  // Handle click on 3D objects
  useEffect(() => {
    if (!containerRef.current || !sceneRef.current || !cameraRef.current) return;

    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    // Track whether cursor is over a clickable item
    let isOverClickable = false;

    // Handle both mouse clicks and touch events
    const handleInteraction = (event: MouseEvent | TouchEvent) => {
      if (!containerRef.current || !sceneRef.current || !cameraRef.current) return;

      // Get the correct client coordinates based on event type
      let clientX, clientY;
      
      if (event.type === 'touchstart') {
        // Prevent default touch behavior only for the 3D scene
        event.preventDefault();
        const touch = (event as TouchEvent).touches[0];
        clientX = touch.clientX;
        clientY = touch.clientY;
      } else {
        clientX = (event as MouseEvent).clientX;
        clientY = (event as MouseEvent).clientY;
      }

      // Calculate mouse position in normalized device coordinates
      const rect = containerRef.current.getBoundingClientRect();
      mouse.x = ((clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((clientY - rect.top) / rect.height) * 2 + 1;

      // Set up raycaster
      raycaster.setFromCamera(mouse, cameraRef.current);

      // Find intersections with all objects in the scene
      const intersects = raycaster.intersectObjects(sceneRef.current.children);

      if (intersects.length > 0) {
        // Get the first intersected object that has a productId
        const clickedObject = intersects.find(obj => 
          obj.object.userData && obj.object.userData.productId
        );
        
        if (clickedObject) {
          const productId = clickedObject.object.userData.productId;
          const product = products.find(p => p.id === productId);
          
          if (product) {
            setSelectedProduct(product);
          }
        }
      }
    };

    // Handle mouse move to change cursor
    const handleMouseMove = (event: MouseEvent) => {
      if (!containerRef.current || !sceneRef.current || !cameraRef.current) return;

      // Calculate mouse position in normalized device coordinates
      const rect = containerRef.current.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      // Set up raycaster
      raycaster.setFromCamera(mouse, cameraRef.current);

      // Find intersections with all objects in the scene
      const intersects = raycaster.intersectObjects(sceneRef.current.children);

      // Check if mouse is over a clickable object
      const overClickable = intersects.some(obj => 
        obj.object.userData && obj.object.userData.productId
      );

      // Update cursor style
      if (overClickable && !isOverClickable) {
        isOverClickable = true;
        if (containerRef.current) {
          containerRef.current.style.cursor = 'pointer';
        }
      } else if (!overClickable && isOverClickable) {
        isOverClickable = false;
        if (containerRef.current) {
          containerRef.current.style.cursor = 'auto';
        }
      }
    };

    // Add both mouse and touch event listeners
    containerRef.current.addEventListener('click', handleInteraction);
    containerRef.current.addEventListener('touchstart', handleInteraction, { passive: false });
    containerRef.current.addEventListener('mousemove', handleMouseMove);

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('click', handleInteraction);
        containerRef.current.removeEventListener('touchstart', handleInteraction);
        containerRef.current.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, []);

  // Handle window resize and device adjustments
  useEffect(() => {
    const handleSpriteResize = () => {
      if (!spritesRef.current || spritesRef.current.length === 0) return;
      
      const isMobileDevice = window.innerWidth < 768;
      const centerSpriteScale = isMobileDevice ? 1.5 : 3;
      const orbitingSpriteScale = isMobileDevice ? 2.1 : 4.2;
      
      // Adjust sprite sizes based on current screen size
      spritesRef.current.forEach(sprite => {
        // Determine which sprite is the center one (no orbit data means it's the center sprite)
        if (!sprite.userData.orbitRadius) {
          sprite.scale.set(centerSpriteScale, centerSpriteScale, centerSpriteScale);
        } else {
          sprite.scale.set(orbitingSpriteScale, orbitingSpriteScale, orbitingSpriteScale);
        }
      });
    };
    
    // Run once on mount
    handleSpriteResize();
    
    // Add to existing window resize events
    window.addEventListener('resize', handleSpriteResize);
    window.addEventListener('orientationchange', handleSpriteResize);
    
    return () => {
      window.removeEventListener('resize', handleSpriteResize);
      window.removeEventListener('orientationchange', handleSpriteResize);
    };
  }, [isLoaded]);

  return (
    <div className="relative h-[90vh] md:min-h-screen flex items-center justify-center bg-black pt-16 md:pt-0 w-full">
      {/* 3D Scene Container */}
      <div 
        ref={containerRef} 
        className="absolute inset-0 z-0 touch-auto"
        aria-label="Interactive 3D galaxy with Mai Tech products"
        style={{ cursor: 'auto' }}
      />
      
      {/* Product Details Modal */}
      {selectedProduct && (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4 pointer-events-auto overflow-y-auto">
          <div 
            className="fixed inset-0 bg-black bg-opacity-70"
            onClick={() => setSelectedProduct(null)}
          ></div>
          <div className="relative bg-maitech-800 rounded-lg max-w-md w-full p-6 shadow-xl border border-primary/20 transform transition-all">
            <button 
              className="absolute top-3 right-3 text-gray-400 hover:text-white"
              onClick={() => setSelectedProduct(null)}
            >
              <X size={20} />
            </button>
            
            <div className="mb-5 flex items-center">
              <img
                src={selectedProduct.logo}
                alt={`${selectedProduct.name} logo`}
                className="w-16 h-16 object-contain mr-4"
                onError={(e) => {
                  // Fallback to colored circle if image fails to load
                  e.currentTarget.style.display = 'none';
                  // Find the next sibling which is the colored circle and show it
                  const nextSibling = e.currentTarget.nextSibling as HTMLElement;
                  if (nextSibling) nextSibling.style.display = 'block';
                }}
              />
              <div 
                className="w-16 h-16 rounded-full"
                style={{ 
                  backgroundColor: `#${selectedProduct.color.toString(16)}`,
                  display: 'none' // Hide by default, show only if image fails to load
                }}
              ></div>
              <h3 className="text-2xl font-bold text-white">{selectedProduct.name}</h3>
            </div>
            
            <p className="text-gray-300 mb-6">{selectedProduct.description}</p>
            
            {selectedProduct.externalLink ? (
              <a
                href={selectedProduct.link}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-center w-full py-2 px-4 bg-primary hover:bg-primary/90 text-white font-medium rounded transition-colors"
              >
                Learn More <ArrowRight className="ml-2 h-4 w-4" />
              </a>
            ) : (
              <Link
                to={selectedProduct.link}
                className="flex items-center justify-center w-full py-2 px-4 bg-primary hover:bg-primary/90 text-white font-medium rounded transition-colors"
              >
                Learn More <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            )}
          </div>
        </div>
      )}
      
      {/* Helper text */}
      <div className={`absolute bottom-12 left-1/2 transform -translate-x-1/2 text-white text-sm bg-black/30 px-4 py-2 rounded-full backdrop-blur-sm transition-opacity duration-1000 ${isLoaded ? 'opacity-70' : 'opacity-0'}`}>
        Tap and drag to explore our products
      </div>
      
      {/* Scroll indicator */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 animate-bounce">
        <svg className="w-6 h-6 text-white opacity-70" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </div>
    </div>
  );
};

export default Hero3D; 