
import { Link } from "react-router-dom";
import { Twitter, Linkedin, Globe } from "lucide-react";
import { Button } from "@/components/ui/button";

interface AuthorProps {
  name: string;
  avatar: string;
  bio: string;
  role: string;
  social?: {
    twitter?: string;
    linkedin?: string;
    website?: string;
  };
}

const BlogPostAuthor = ({ name, avatar, bio, role, social }: AuthorProps) => {
  return (
    <div className="flex flex-col sm:flex-row items-center sm:items-start gap-6 p-6 bg-maitech-800/50 rounded-lg border border-primary/10">
      <div className="w-24 h-24 rounded-full overflow-hidden flex-shrink-0">
        <img 
          src={avatar} 
          alt={name} 
          className="w-full h-full object-cover"
        />
      </div>
      
      <div className="flex-1 text-center sm:text-left">
        <h3 className="text-xl font-bold text-white">{name}</h3>
        <p className="text-primary/80 text-sm mb-3">{role}</p>
        
        <p className="text-gray-400 text-sm mb-4">{bio}</p>
        
        {social && (
          <div className="flex justify-center sm:justify-start space-x-2">
            {social.twitter && (
              <a href={social.twitter} target="_blank" rel="noopener noreferrer" aria-label={`${name}'s Twitter`}>
                <Button size="icon" variant="outline" className="w-8 h-8 rounded-full border-primary/20">
                  <Twitter className="h-3.5 w-3.5" />
                </Button>
              </a>
            )}
            
            {social.linkedin && (
              <a href={social.linkedin} target="_blank" rel="noopener noreferrer" aria-label={`${name}'s LinkedIn`}>
                <Button size="icon" variant="outline" className="w-8 h-8 rounded-full border-primary/20">
                  <Linkedin className="h-3.5 w-3.5" />
                </Button>
              </a>
            )}
            
            {social.website && (
              <a href={social.website} target="_blank" rel="noopener noreferrer" aria-label={`${name}'s Website`}>
                <Button size="icon" variant="outline" className="w-8 h-8 rounded-full border-primary/20">
                  <Globe className="h-3.5 w-3.5" />
                </Button>
              </a>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogPostAuthor;
