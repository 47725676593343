
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import BlogCard, { BlogPost } from "@/components/BlogCard";
import BlogSidebar from "@/components/BlogSidebar";
import { Button } from "@/components/ui/button";
import { Search } from "lucide-react";

// Mock data for blog posts
const blogPosts: BlogPost[] = [
  {
    id: "1",
    slug: "introduction-to-ai-powered-automation",
    title: "Introduction to AI-Powered Automation in Modern Businesses",
    excerpt: "Discover how AI-powered automation is transforming business operations and creating new opportunities for growth and efficiency.",
    coverImage: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    date: "June 15, 2023",
    author: {
      name: "Alex Chen",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    },
    tags: ["AI", "Automation", "Business"],
    readTime: "5 min",
  },
  {
    id: "2",
    slug: "machine-learning-for-predictive-analytics",
    title: "Leveraging Machine Learning for Predictive Analytics",
    excerpt: "How businesses are using machine learning algorithms to predict trends and make data-driven decisions.",
    coverImage: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    date: "July 22, 2023",
    author: {
      name: "Sarah Johnson",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    },
    tags: ["Machine Learning", "Analytics", "Data Science"],
    readTime: "7 min",
  },
  {
    id: "3",
    slug: "blockchain-in-supply-chain-management",
    title: "Blockchain Technology in Modern Supply Chain Management",
    excerpt: "Exploring how blockchain is revolutionizing supply chain transparency, efficiency, and security across industries.",
    coverImage: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    date: "August 10, 2023",
    author: {
      name: "Michael Rodriguez",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    },
    tags: ["Blockchain", "Supply Chain", "Technology"],
    readTime: "6 min",
  },
  {
    id: "4",
    slug: "ethical-considerations-in-ai-development",
    title: "Ethical Considerations in AI Development and Deployment",
    excerpt: "Examining the ethical challenges and responsibilities in developing and implementing AI systems in society.",
    coverImage: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    date: "September 5, 2023",
    author: {
      name: "Emma Williams",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    },
    tags: ["AI Ethics", "Technology", "Society"],
    readTime: "8 min",
  },
  {
    id: "5",
    slug: "cloud-migration-strategies",
    title: "Effective Cloud Migration Strategies for Enterprises",
    excerpt: "A comprehensive guide to planning and executing successful cloud migration for enterprise applications and data.",
    coverImage: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    date: "October 18, 2023",
    author: {
      name: "David Kim",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    },
    tags: ["Cloud Computing", "Enterprise", "Migration"],
    readTime: "10 min",
  },
  {
    id: "6",
    slug: "iot-in-smart-manufacturing",
    title: "IoT Applications in Smart Manufacturing",
    excerpt: "How Internet of Things (IoT) technology is transforming manufacturing processes, quality control, and production efficiency.",
    coverImage: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    date: "November 30, 2023",
    author: {
      name: "Priya Patel",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    },
    tags: ["IoT", "Manufacturing", "Industry 4.0"],
    readTime: "6 min",
  },
];

// Mock data for categories
const categories = [
  { name: "Artificial Intelligence", count: 12 },
  { name: "Data Science", count: 8 },
  { name: "Web Development", count: 10 },
  { name: "Cloud Computing", count: 7 },
  { name: "Blockchain", count: 5 },
  { name: "Cybersecurity", count: 9 }
];

// Mock data for tags
const tags = [
  "AI", "Machine Learning", "Data Analytics", "Cloud", "DevOps",
  "Security", "Web Design", "Mobile", "IoT", "Blockchain",
  "Python", "JavaScript", "React", "UX/UI", "API"
];

const Blog = () => {
  // Featured post is the first post
  const featuredPost = blogPosts[0];
  // Regular posts are all other posts
  const regularPosts = blogPosts.slice(1);
  // Popular posts for sidebar (could be based on views or other metrics)
  const popularPosts = [blogPosts[1], blogPosts[2], blogPosts[3]];

  useEffect(() => {
    // Scroll to top when the page loads
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen bg-maitech-900 text-foreground">
      <Helmet>
        <title>Blog | Mai Tech - Expert AI and Technology Insights</title>
        <meta name="description" content="Explore the latest expert insights on AI, machine learning, data analytics, web development, and emerging technology trends from Mai Tech specialists." />
        <meta name="keywords" content="AI, artificial intelligence, machine learning, data analytics, web development, technology trends, tech blog, digital transformation" />
        <meta property="og:title" content="Mai Tech Blog - Expert Technology Insights and Trends" />
        <meta property="og:description" content="Get expert insights on AI, machine learning, data analytics, and the latest technology trends from industry specialists." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://maitech.com/blog" />
        <meta property="og:image" content="https://maitech.com/og-image.png" />
        <link rel="canonical" href="https://maitech.com/blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Mai Tech Blog - Expert Technology Insights" />
        <meta name="twitter:description" content="Get expert insights on AI, machine learning, data analytics, and the latest technology trends from industry specialists." />
        <meta name="twitter:image" content="https://maitech.com/og-image.png" />
        <script type="application/ld+json">{`
          {
            "@context": "https://schema.org",
            "@type": "Blog",
            "name": "Mai Tech Blog",
            "description": "Expert insights on AI, machine learning, data analytics, and technology trends",
            "url": "https://maitech.com/blog",
            "publisher": {
              "@type": "Organization",
              "name": "Mai Tech",
              "logo": {
                "@type": "ImageObject",
                "url": "https://maitech.com/logo.png"
              }
            }
          }
        `}</script>
      </Helmet>

      <Navbar />
      
      <main className="pt-20 pb-20">
        <div className="container mx-auto px-4">
          {/* Blog Header */}
          <div className="text-center mb-16">
            <h1 className="text-4xl md:text-5xl font-bold mb-4 gradient-text">Mai Tech Blog</h1>
            <p className="text-xl text-gray-400 max-w-2xl mx-auto">
              Expert insights on AI, machine learning, and emerging technology trends
            </p>
            
            {/* Search Bar */}
            <div className="max-w-xl mx-auto mt-8">
              <div className="relative">
                <input 
                  type="text" 
                  placeholder="Search articles..." 
                  className="w-full py-3 px-4 pl-5 pr-12 rounded-full bg-maitech-800 border border-primary/20 focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary"
                />
                <button className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-primary">
                  <Search className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>

          {/* Featured Post */}
          <div className="mb-16">
            <h2 className="text-2xl font-bold mb-6 border-l-4 border-primary pl-4">Featured Article</h2>
            <div className="grid grid-cols-1">
              <BlogCard post={featuredPost} featured={true} />
            </div>
          </div>

          {/* Blog Content */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            {/* Blog Posts */}
            <div className="lg:col-span-2">
              <h2 className="text-2xl font-bold mb-6 border-l-4 border-primary pl-4">Latest Articles</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {regularPosts.map(post => (
                  <BlogCard key={post.id} post={post} />
                ))}
              </div>
              
              {/* Pagination */}
              <div className="flex justify-center mt-12">
                <div className="flex space-x-1">
                  <Button variant="outline" className="border-primary/20" disabled>
                    Previous
                  </Button>
                  <Button className="bg-primary">1</Button>
                  <Button variant="outline" className="border-primary/20">2</Button>
                  <Button variant="outline" className="border-primary/20">3</Button>
                  <Button variant="outline" className="border-primary/20">
                    Next
                  </Button>
                </div>
              </div>
            </div>
            
            {/* Sidebar */}
            <div className="lg:col-span-1">
              <BlogSidebar 
                popularPosts={popularPosts}
                categories={categories}
                tags={tags}
              />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Blog;
