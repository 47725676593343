
import BlogCard, { type BlogPost } from "./BlogCard";

interface RelatedBlogPostsProps {
  posts: BlogPost[];
  currentPostId: string;
}

const RelatedBlogPosts = ({ posts, currentPostId }: RelatedBlogPostsProps) => {
  // Filter out the current post
  const filteredPosts = posts.filter(post => post.id !== currentPostId);
  
  // Take only the first 3 posts
  const relatedPosts = filteredPosts.slice(0, 3);

  if (relatedPosts.length === 0) {
    return null;
  }

  return (
    <div className="mt-16">
      <h2 className="text-2xl font-bold mb-6">Related Articles</h2>
      <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {relatedPosts.map(post => (
          <BlogCard key={post.id} post={post} />
        ))}
      </div>
    </div>
  );
};

export default RelatedBlogPosts;
