import { useParams, Navigate } from "react-router-dom";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Helmet } from "react-helmet-async";
import { ArrowLeft, Check, Award, FileText } from "lucide-react";
import { Button } from "@/components/ui/button";
import LanguageAwareLink from "@/components/LanguageAwareLink";
import RiceGoCase from "./case-studies/RiceGoCase";

const caseStudiesData = {
  "ricego-digital-transformation": {
    title: "Digital Transformation for RiceGo F&B Chain",
    subtitle: "Food & Beverage",
    summary: "How we helped a leading Vietnamese F&B brand with 10+ locations integrate AI solutions into their customer service and management systems.",
    challenge: "RiceGo, a popular rice-based food chain with over 10 locations across Saigon, was facing challenges in scaling their operations while maintaining consistent customer experience. Their manual ordering and management systems were becoming inefficient as they expanded, leading to longer wait times, inconsistent service quality, and operational bottlenecks. They needed a comprehensive digital solution that could streamline their operations while enhancing customer engagement across all their locations.",
    solution: "We developed a complete digital ecosystem for RiceGo that included:\n\n- Custom-built mobile app for customers with online ordering, loyalty program, and personalized recommendations\n- Modern, responsive website with integrated online ordering system\n- AI-powered chatbot for customer service and order assistance\n- Centralized management dashboard for real-time monitoring of all locations\n- Inventory management system with predictive ordering based on historical data\n- Staff scheduling and performance tracking tools",
    results: [
      "35% increase in customer satisfaction scores",
      "42% reduction in order processing time",
      "28% boost in operational efficiency",
      "53% increase in repeat customer visits",
      "18% reduction in food waste through better inventory management"
    ],
    testimonial: {
      quote: "Mai Tech completely transformed our business. The digital solutions they provided not only improved our operational efficiency but also enhanced the customer experience significantly. The AI integrations have given us insights we never had before, allowing us to make better business decisions.",
      author: "Tran Minh",
      position: "Operations Director",
      company: "RiceGo"
    },
    mainImage: "/lovable-uploads/fc6e8cca-180c-44eb-bdac-0f05fde126bf.png",
    images: [
      "/lovable-uploads/adf3f7b6-6781-4473-9c52-beef102acb28.png", 
      "/lovable-uploads/8e04c740-a159-4ab0-9ca5-c3dfe9f93a32.png"
    ],
    technologies: ["Mobile App Development", "Web Development", "Natural Language Processing", "Machine Learning", "Real-time Analytics"],
    implementationTime: "14 weeks",
    roi: "7 months"
  }
};

const CaseStudy = () => {
  const { slug } = useParams();
  
  // Ensure slug is properly checked with null coalescing
  const cleanSlug = slug || '';
  
  if (cleanSlug === "ricego-digital-transformation") {
    return <RiceGoCase />;
  }
  
  // Use proper type casting with non-null assertion if needed
  const caseStudy = caseStudiesData[cleanSlug as keyof typeof caseStudiesData];

  if (!caseStudy) {
    return (
      <div className="min-h-screen bg-maitech-900">
        <Navbar />
        <div className="container mx-auto px-4 py-16 text-center">
          <h1 className="text-3xl font-bold mb-4">Case Study Not Found</h1>
          <p className="mb-8">The case study you're looking for doesn't exist or has been moved.</p>
          <LanguageAwareLink to="/case-studies">
            <Button>View MAI Tech Solutions</Button>
          </LanguageAwareLink>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-maitech-900">
      <Helmet>
        <title>{`${caseStudy.title} | MAI Tech - Case Study`}</title>
        <meta name="description" content={caseStudy.summary} />
        <meta property="og:title" content={`${caseStudy.title} | Case Study`} />
        <meta property="og:description" content={caseStudy.summary} />
        <meta property="og:image" content={caseStudy.mainImage} />
        <meta property="og:type" content="article" />
      </Helmet>

      <Navbar />
      
      <div className="container mx-auto px-4 py-16">
        <div className="mb-8">
          <LanguageAwareLink to="/case-studies" className="text-primary flex items-center hover:underline">
            <ArrowLeft className="h-4 w-4 mr-2" /> Back to MAI Tech Solutions
          </LanguageAwareLink>
        </div>

        <header className="mb-16">
          <div className="flex items-center mb-3">
            <span className="text-sm bg-primary/20 text-primary px-3 py-1 rounded-full">
              {caseStudy.subtitle}
            </span>
          </div>
          <h1 className="text-4xl md:text-5xl font-bold gradient-text mb-6">{caseStudy.title}</h1>
          <p className="text-xl text-gray-300 max-w-3xl">{caseStudy.summary}</p>
        </header>

        <div className="mb-16">
          <img 
            src={caseStudy.mainImage} 
            alt={caseStudy.title}
            className="w-full h-auto rounded-lg shadow-xl object-cover max-h-[500px]"
          />
        </div>

        <div className="grid md:grid-cols-3 gap-12 mb-16">
          <div className="bg-maitech-800 p-6 rounded-lg border border-maitech-700">
            <div className="flex items-center mb-4">
              <Award className="h-6 w-6 text-primary mr-3" />
              <h3 className="text-xl font-semibold">Key Results</h3>
            </div>
            <ul className="space-y-3">
              {caseStudy.results.map((result, idx) => (
                <li key={idx} className="flex items-start">
                  <Check className="text-primary mt-1 mr-2 h-5 w-5 flex-shrink-0" />
                  <span className="text-gray-200">{result}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="bg-maitech-800 p-6 rounded-lg border border-maitech-700">
            <div className="flex items-center mb-4">
              <FileText className="h-6 w-6 text-primary mr-3" />
              <h3 className="text-xl font-semibold">Project Details</h3>
            </div>
            <div className="space-y-3">
              <div>
                <p className="text-gray-400 text-sm">Implementation Time</p>
                <p className="text-white">{caseStudy.implementationTime}</p>
              </div>
              <div>
                <p className="text-gray-400 text-sm">ROI Period</p>
                <p className="text-white">{caseStudy.roi}</p>
              </div>
              <div>
                <p className="text-gray-400 text-sm">Technologies Used</p>
                <div className="flex flex-wrap gap-2 mt-2">
                  {caseStudy.technologies.map((tech, idx) => (
                    <span 
                      key={idx} 
                      className="bg-maitech-700/70 px-2 py-1 rounded-md text-xs text-gray-200"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bg-maitech-800 p-6 rounded-lg border border-maitech-700">
            <div className="flex items-center mb-4">
              <svg className="h-6 w-6 text-primary mr-3" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"></path>
              </svg>
              <h3 className="text-xl font-semibold">Client Testimonial</h3>
            </div>
            <blockquote className="text-gray-300 italic mb-4">
              "{caseStudy.testimonial.quote}"
            </blockquote>
            <div>
              <p className="text-white font-medium">{caseStudy.testimonial.author}</p>
              <p className="text-gray-400 text-sm">{caseStudy.testimonial.position}</p>
              <p className="text-gray-400 text-sm">{caseStudy.testimonial.company}</p>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-2 gap-12 mb-16">
          <div>
            <h2 className="text-2xl font-bold mb-6 text-white">The Challenge</h2>
            <div className="prose-article bg-maitech-800/50 backdrop-blur rounded-lg p-6 border border-maitech-700/50">
              <p>{caseStudy.challenge}</p>
            </div>
          </div>
          
          <div>
            <h2 className="text-2xl font-bold mb-6 text-white">Our Solution</h2>
            <div className="prose-article bg-maitech-800/50 backdrop-blur rounded-lg p-6 border border-maitech-700/50">
              <p>{caseStudy.solution}</p>
            </div>
          </div>
        </div>

        <div className="mb-16">
          <h2 className="text-2xl font-bold mb-6 text-white">Implementation Gallery</h2>
          <div className="grid md:grid-cols-2 gap-6">
            {caseStudy.images.map((image, idx) => (
              <div key={idx} className="bg-maitech-800/50 p-4 rounded-lg border border-maitech-700">
                <img 
                  src={image} 
                  alt={`${caseStudy.title} implementation ${idx + 1}`}
                  className="w-full h-auto rounded-lg object-cover aspect-video"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="mt-10 mb-6 flex justify-center">
          <div className="max-w-sm">
            <img 
              src="/lovable-uploads/5d34bcea-a8e6-4105-9526-2cd245fa7342.png"
              alt="RiceGo Logo"
              className="w-full h-auto"
            />
          </div>
        </div>

        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold mb-6 text-white">Ready to Achieve Similar Results?</h2>
          <p className="text-gray-300 mb-8 max-w-2xl mx-auto">
            Let's discuss how our solutions can help your business overcome challenges and drive growth.
          </p>
          <div className="flex flex-wrap justify-center gap-4">
            <LanguageAwareLink to="/contact">
              <Button>
                Contact Us
              </Button>
            </LanguageAwareLink>
            <LanguageAwareLink to="/products">
              <Button variant="outline" className="border-primary text-primary hover:bg-primary/10">
                Explore Our Products
              </Button>
            </LanguageAwareLink>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default CaseStudy;
