
import { Bot, Database } from "lucide-react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";

const AiSolutions = () => {
  // Hard-code language to English
  const language = 'en';

  const content = {
    en: {
      title: "Advanced AI Solutions",
      subtitle: "Transforming Businesses with Cutting-Edge Technology",
      description:
        "Our AI solutions are designed to empower businesses by automating processes, optimizing operations, and unlocking valuable insights. With a focus on innovation and practicality, we deliver customized AI solutions tailored to your unique needs.",
      features: [
        {
          icon: <Bot className="h-6 w-6" />,
          title: "Process Automation",
          description:
            "Streamline repetitive tasks, improve efficiency, and reduce human errors using AI-driven automation tools.",
        },
        {
          icon: <Database className="h-6 w-6" />,
          title: "Data Analytics and Insights",
          description:
            "Harness the power of AI to analyze large datasets, identify trends, and make data-driven decisions.",
        },
      ],
      applications: {
        title: "Applications of Our AI Solutions",
        items: [
          "E-commerce: Personalized recommendations, dynamic pricing",
          "Healthcare: Predictive analysis, patient care optimization",
          "Finance: Fraud detection, risk assessment",
        ],
      },
      workflow: {
        title: "Our Workflow",
        steps: [
          "Consultation to understand business challenges",
          "Solution design tailored to client needs",
          "Development and testing of the AI model",
          "Deployment and continuous support",
        ],
      },
    },
  };

  return (
    <div className="min-h-screen bg-maitech-900">
      <Navbar />
      <div className="container mx-auto px-4 pt-20 pb-12">
        <div className="text-center mb-8 md:mb-12 animate-fade-up">
          <h1 className="text-3xl md:text-5xl font-bold mb-3 md:mb-4 text-white">
            {content[language].title}
          </h1>
          <p className="text-lg md:text-xl text-gray-300">{content[language].subtitle}</p>
          <p className="mt-3 md:mt-4 text-gray-400 max-w-3xl mx-auto text-sm md:text-base">
            {content[language].description}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6 mb-8 md:mb-12 animate-fade-up">
          {content[language].features.map((feature, index) => (
            <Card
              key={index}
              className="bg-maitech-900/50 border-maitech-700 hover:border-primary transition-colors"
            >
              <CardHeader className="p-4 md:p-6">
                <div className="h-10 w-10 md:h-12 md:w-12 rounded-lg bg-primary/10 flex items-center justify-center mb-3 md:mb-4">
                  {feature.icon}
                </div>
                <CardTitle className="text-white text-lg md:text-xl">{feature.title}</CardTitle>
              </CardHeader>
              <CardContent className="p-4 md:p-6 pt-0 md:pt-0">
                <CardDescription className="text-gray-400 text-sm md:text-base">
                  {feature.description}
                </CardDescription>
              </CardContent>
            </Card>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-12 mb-8 md:mb-12">
          <div className="animate-fade-up">
            <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-white">
              {content[language].applications.title}
            </h2>
            <ul className="space-y-3 md:space-y-4">
              {content[language].applications.items.map((item, index) => (
                <li
                  key={index}
                  className="flex items-start space-x-3 text-gray-300 text-sm md:text-base"
                >
                  <span className="text-primary mt-1">•</span>
                  <span>{item}</span>
                </li>
              ))}
            </ul>
          </div>

          <div className="animate-fade-up">
            <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-6 text-white">
              {content[language].workflow.title}
            </h2>
            <div className="space-y-3 md:space-y-4">
              {content[language].workflow.steps.map((step, index) => (
                <div
                  key={index}
                  className="flex items-start space-x-3 text-gray-300 text-sm md:text-base"
                >
                  <span className="flex-shrink-0 w-5 h-5 md:w-6 md:h-6 rounded-full bg-primary/20 flex items-center justify-center text-primary text-xs md:text-sm">
                    {index + 1}
                  </span>
                  <span>{step}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AiSolutions;
