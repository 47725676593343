
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { ArrowLeft, Calendar, Clock, User, Tag } from "lucide-react";
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import { Separator } from "@/components/ui/separator";
import BlogPostAuthor from "@/components/BlogPostAuthor";
import ShareBlogPost from "@/components/ShareBlogPost";
import RelatedBlogPosts from "@/components/RelatedBlogPosts";
import type { BlogPost as BlogPostType } from "@/components/BlogCard";
import BlogSidebar from "@/components/BlogSidebar";

// Mock data for blog posts (this would typically come from an API or CMS)
const blogPosts: BlogPostType[] = [
  {
    id: "1",
    slug: "introduction-to-ai-powered-automation",
    title: "Introduction to AI-Powered Automation in Modern Businesses",
    excerpt: "Discover how AI-powered automation is transforming business operations and creating new opportunities for growth and efficiency.",
    coverImage: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    date: "June 15, 2023",
    author: {
      name: "Alex Chen",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    },
    tags: ["AI", "Automation", "Business"],
    readTime: "5 min",
  },
  {
    id: "2",
    slug: "machine-learning-for-predictive-analytics",
    title: "Leveraging Machine Learning for Predictive Analytics",
    excerpt: "How businesses are using machine learning algorithms to predict trends and make data-driven decisions.",
    coverImage: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    date: "July 22, 2023",
    author: {
      name: "Sarah Johnson",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
    },
    tags: ["Machine Learning", "Analytics", "Data Science"],
    readTime: "7 min",
  },
  // ... other posts from the Blog.tsx file
];

// Mock data for full blog post content
const blogPostContent = {
  "introduction-to-ai-powered-automation": {
    content: `
      <h2>The Rise of AI Automation</h2>
      <p>Artificial Intelligence (AI) has rapidly evolved from a futuristic concept to an essential business tool. Today's organizations are increasingly adopting AI-powered automation to streamline operations, reduce costs, and create new opportunities for growth.</p>
      
      <p>The integration of AI into business processes represents a fundamental shift in how companies operate. Unlike traditional automation, which follows predefined rules, AI systems can learn from data, adapt to new inputs, and perform tasks that previously required human intelligence.</p>
      
      <h2>Key Applications of AI Automation</h2>
      <p>AI automation is being applied across various business functions with impressive results:</p>
      
      <h3>Customer Service</h3>
      <p>AI-powered chatbots and virtual assistants are handling customer inquiries with increasing sophistication. These systems can understand natural language, provide personalized responses, and seamlessly hand off complex issues to human agents when necessary.</p>
      
      <h3>Data Processing and Analysis</h3>
      <p>AI tools can process vast amounts of structured and unstructured data, extracting valuable insights that would be impossible for humans to identify manually. This capability is transforming decision-making processes across industries.</p>
      
      <h3>Supply Chain Optimization</h3>
      <p>Predictive analytics powered by AI can forecast demand, optimize inventory levels, and identify potential disruptions before they occur, resulting in more resilient and efficient supply chains.</p>
      
      <h2>Implementation Challenges</h2>
      <p>Despite the clear benefits, implementing AI automation comes with challenges:</p>
      
      <ul>
        <li><strong>Data Quality:</strong> AI systems require high-quality, relevant data to function effectively.</li>
        <li><strong>Integration:</strong> Connecting AI solutions with existing systems and workflows can be complex.</li>
        <li><strong>Change Management:</strong> Employees may resist automation if they fear job displacement.</li>
        <li><strong>Ethical Considerations:</strong> Organizations must address issues like bias, privacy, and transparency.</li>
      </ul>
      
      <h2>Strategic Approaches to AI Implementation</h2>
      <p>Successful AI automation initiatives typically follow these strategic approaches:</p>
      
      <ol>
        <li><strong>Start with a Clear Business Case:</strong> Focus on specific problems where AI can deliver measurable value.</li>
        <li><strong>Prioritize Data Strategy:</strong> Ensure you have the right data infrastructure to support AI initiatives.</li>
        <li><strong>Adopt an Incremental Approach:</strong> Begin with pilot projects, learn from the results, and scale gradually.</li>
        <li><strong>Invest in Skills Development:</strong> Train employees to work alongside AI systems and develop new capabilities.</li>
        <li><strong>Establish Governance Frameworks:</strong> Create clear guidelines for the ethical use of AI within your organization.</li>
      </ol>
      
      <h2>The Future of AI Automation</h2>
      <p>Looking ahead, AI automation will continue to evolve and expand its capabilities. Emerging trends include:</p>
      
      <p><strong>Hyperautomation:</strong> Combining multiple AI technologies to automate increasingly complex processes.</p>
      
      <p><strong>Human-AI Collaboration:</strong> Developing more intuitive interfaces that enable seamless interaction between humans and AI systems.</p>
      
      <p><strong>Democratization of AI:</strong> Making AI tools more accessible to non-technical users through low-code/no-code platforms.</p>
      
      <h2>Conclusion</h2>
      <p>AI-powered automation represents a transformative opportunity for businesses of all sizes. By approaching implementation strategically and addressing challenges proactively, organizations can harness the power of AI to achieve significant competitive advantages in today's digital economy.</p>
    `,
    authorBio: {
      name: "Alex Chen",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
      bio: "Alex Chen is an AI Research Scientist with over 10 years of experience in machine learning and automation technologies. He has helped numerous enterprises implement AI solutions that drive business value.",
      role: "AI Research Scientist",
      social: {
        twitter: "https://twitter.com/alexchen",
        linkedin: "https://linkedin.com/in/alexchen",
        website: "https://alexchen.com"
      }
    }
  },
  "machine-learning-for-predictive-analytics": {
    content: `
      <h2>Understanding Predictive Analytics</h2>
      <p>Predictive analytics uses historical data, statistical algorithms, and machine learning techniques to identify the likelihood of future outcomes. This powerful approach is transforming how businesses make decisions across all sectors.</p>
      
      <p>Unlike descriptive analytics (which tells you what happened) or diagnostic analytics (which explains why it happened), predictive analytics focuses on what will happen next. This forward-looking perspective enables proactive decision-making and strategic planning.</p>
      
      <h2>The Machine Learning Revolution</h2>
      <p>Machine learning has revolutionized predictive analytics by enabling systems to improve their predictions automatically through experience. As these algorithms process more data, they become increasingly accurate and valuable for business applications.</p>
      
      <h2>Key Applications Across Industries</h2>
      
      <h3>Retail and E-commerce</h3>
      <p>Retailers use predictive analytics to forecast demand, optimize inventory, personalize recommendations, and detect fraud. These capabilities have become essential in the highly competitive retail landscape.</p>
      
      <h3>Financial Services</h3>
      <p>Banks and financial institutions leverage predictive models for credit scoring, risk assessment, fraud detection, and algorithmic trading. Machine learning enhances these predictions by identifying subtle patterns that traditional statistical models might miss.</p>
      
      <h3>Healthcare</h3>
      <p>In healthcare, predictive analytics supports early disease detection, patient risk stratification, and resource allocation. Machine learning models can analyze complex medical data to identify patients at risk of readmission or complications.</p>
      
      <h2>Common Machine Learning Techniques for Prediction</h2>
      
      <ul>
        <li><strong>Regression Analysis:</strong> Predicts continuous values based on relationships between variables.</li>
        <li><strong>Classification:</strong> Categorizes data into predefined classes, useful for yes/no predictions.</li>
        <li><strong>Clustering:</strong> Groups similar data points together to reveal hidden patterns.</li>
        <li><strong>Time Series Analysis:</strong> Forecasts future values based on previously observed values over time.</li>
        <li><strong>Ensemble Methods:</strong> Combines multiple models to improve prediction accuracy.</li>
      </ul>
      
      <h2>Implementation Challenges and Solutions</h2>
      
      <p><strong>Data Quality and Preparation:</strong> The success of predictive models depends heavily on the quality of input data. Organizations must invest in data cleaning, normalization, and feature engineering.</p>
      
      <p><strong>Model Selection and Tuning:</strong> Choosing the right algorithm and optimizing its parameters requires expertise and experimentation. Automated machine learning (AutoML) tools can help address this challenge.</p>
      
      <p><strong>Interpretability vs. Accuracy:</strong> Complex models often provide higher accuracy but less transparency. Organizations must balance these factors based on their specific use case and regulatory requirements.</p>
      
      <h2>Best Practices for Successful Implementation</h2>
      
      <ol>
        <li><strong>Start with Clear Business Objectives:</strong> Define specific problems and success metrics before selecting models.</li>
        <li><strong>Invest in Data Infrastructure:</strong> Build robust systems for data collection, storage, and processing.</li>
        <li><strong>Build Cross-functional Teams:</strong> Combine data scientists, domain experts, and IT professionals.</li>
        <li><strong>Implement Continuous Monitoring:</strong> Track model performance over time and retrain when accuracy declines.</li>
        <li><strong>Establish Governance Frameworks:</strong> Develop clear guidelines for model development, validation, and deployment.</li>
      </ol>
      
      <h2>Future Trends</h2>
      
      <p><strong>Automated Machine Learning (AutoML):</strong> Making predictive analytics more accessible to non-technical users by automating model selection and hyperparameter tuning.</p>
      
      <p><strong>Deep Learning for Complex Data:</strong> Leveraging neural networks to analyze unstructured data like images, text, and audio for prediction.</p>
      
      <p><strong>Explainable AI:</strong> Developing techniques to make complex models more interpretable and transparent.</p>
      
      <h2>Conclusion</h2>
      <p>Machine learning for predictive analytics represents a transformative capability for data-driven organizations. By understanding the techniques, addressing implementation challenges, and following best practices, businesses can leverage these tools to gain competitive advantages through more accurate forecasting and proactive decision-making.</p>
    `,
    authorBio: {
      name: "Sarah Johnson",
      avatar: "/lovable-uploads/placeholder.svg", // Replace with an actual image
      bio: "Sarah Johnson is a Data Science Director with expertise in developing predictive analytics solutions for Fortune 500 companies. She specializes in machine learning applications for business intelligence and decision support systems.",
      role: "Data Science Director",
      social: {
        twitter: "https://twitter.com/sarahjohnson",
        linkedin: "https://linkedin.com/in/sarahjohnson"
      }
    }
  }
};

// Mock data for categories and tags (for sidebar)
const categories = [
  { name: "Artificial Intelligence", count: 12 },
  { name: "Data Science", count: 8 },
  { name: "Web Development", count: 10 },
  { name: "Cloud Computing", count: 7 },
  { name: "Blockchain", count: 5 },
  { name: "Cybersecurity", count: 9 }
];

const tags = [
  "AI", "Machine Learning", "Data Analytics", "Cloud", "DevOps",
  "Security", "Web Design", "Mobile", "IoT", "Blockchain",
  "Python", "JavaScript", "React", "UX/UI", "API"
];

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const [post, setPost] = useState<BlogPostType | null>(null);
  const [postContent, setPostContent] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Scroll to top when the component loads
    window.scrollTo(0, 0);
    
    // In a real app, this would be an API call
    // For now, we'll use our mock data
    if (slug) {
      const foundPost = blogPosts.find(p => p.slug === slug);
      if (foundPost) {
        setPost(foundPost);
        // @ts-ignore - Mock content data
        setPostContent(blogPostContent[slug]);
      }
    }
    setLoading(false);
  }, [slug]);

  if (loading) {
    return (
      <div className="min-h-screen bg-maitech-900 text-foreground flex justify-center items-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary"></div>
      </div>
    );
  }

  if (!post || !postContent) {
    return (
      <div className="min-h-screen bg-maitech-900 text-foreground">
        <Navbar />
        <main className="container mx-auto px-4 py-20">
          <div className="text-center">
            <h1 className="text-3xl font-bold mb-4">Blog Post Not Found</h1>
            <p className="text-gray-400 mb-8">The blog post you're looking for doesn't exist or has been moved.</p>
            <Link to="/blog" className="text-primary hover:underline">
              ← Return to Blog
            </Link>
          </div>
        </main>
        <Footer />
      </div>
    );
  }

  const { title, date, author, tags, readTime, coverImage, excerpt } = post;
  const { content, authorBio } = postContent;
  const pageUrl = window.location.href;
  const popularPosts = blogPosts.filter(p => p.id !== post.id).slice(0, 3);

  // Format the date for structured data
  const formattedDate = new Date(date).toISOString();
  
  // Create structured data for the article
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": title,
    "description": excerpt,
    "image": coverImage,
    "datePublished": formattedDate,
    "dateModified": formattedDate,
    "author": {
      "@type": "Person",
      "name": author.name
    },
    "publisher": {
      "@type": "Organization",
      "name": "Mai Tech",
      "logo": {
        "@type": "ImageObject",
        "url": "https://maitech.com/logo.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": pageUrl
    },
    "keywords": tags.join(', ')
  };

  return (
    <div className="min-h-screen bg-maitech-900 text-foreground">
      <Helmet>
        <title>{title} | Mai Tech Blog</title>
        <meta name="description" content={excerpt} />
        <meta name="keywords" content={tags.join(', ')} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image" content={coverImage} />
        <link rel="canonical" href={pageUrl} />
        
        {/* Article specific meta tags */}
        <meta property="article:published_time" content={formattedDate} />
        <meta property="article:author" content={author.name} />
        {tags.map((tag, index) => (
          <meta key={index} property="article:tag" content={tag} />
        ))}
        
        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={excerpt} />
        <meta name="twitter:image" content={coverImage} />
        
        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>

      <Navbar />
      
      <main className="pt-20 pb-20">
        <div className="container mx-auto px-4">
          {/* Back to Blog Link */}
          <div className="mb-8">
            <Link to="/blog" className="text-gray-400 hover:text-primary inline-flex items-center">
              <ArrowLeft className="h-4 w-4 mr-2" />
              Back to Blog
            </Link>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
            <div className="lg:col-span-2">
              <article>
                {/* Blog Post Header */}
                <header className="mb-8">
                  <div className="flex flex-wrap gap-2 mb-4">
                    {tags.map((tag, index) => (
                      <Link 
                        key={index}
                        to={`/blog/tag/${tag.toLowerCase().replace(/ /g, '-')}`}
                        className="text-xs px-3 py-1 rounded-full bg-primary/10 text-primary hover:bg-primary/20 transition-colors flex items-center"
                      >
                        <Tag className="h-3 w-3 mr-1" />
                        {tag}
                      </Link>
                    ))}
                  </div>
                  
                  <h1 className="text-3xl md:text-4xl font-bold text-white mb-4">{title}</h1>
                  
                  <p className="text-gray-400 text-lg mb-5">{excerpt}</p>
                  
                  <div className="flex flex-wrap items-center text-sm text-gray-400 gap-5 p-4 bg-maitech-800 rounded-lg border border-primary/10 mb-6">
                    <div className="flex items-center">
                      <div className="w-10 h-10 rounded-full overflow-hidden mr-3">
                        <img 
                          src={author.avatar || "/lovable-uploads/placeholder.svg"} 
                          alt={author.name} 
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <span className="font-medium">{author.name}</span>
                    </div>
                    
                    <div className="flex items-center">
                      <Calendar className="h-4 w-4 mr-2 text-primary/70" />
                      <span>{date}</span>
                    </div>
                    
                    <div className="flex items-center">
                      <Clock className="h-4 w-4 mr-2 text-primary/70" />
                      <span>{readTime} read</span>
                    </div>
                  </div>
                </header>

                {/* Featured Image */}
                <div className="rounded-lg overflow-hidden mb-8 h-64 md:h-[400px]">
                  <img 
                    src={coverImage} 
                    alt={title}
                    className="w-full h-full object-cover"
                  />
                </div>

                {/* Blog Content - Improved styling for better readability */}
                <div className="prose-article bg-maitech-800/50 backdrop-blur rounded-lg p-8 border border-maitech-700/50 mb-10">
                  <div 
                    dangerouslySetInnerHTML={{ __html: content }}
                    className="prose prose-lg max-w-none prose-headings:text-white prose-headings:font-bold prose-h2:text-2xl prose-h2:mt-8 prose-h2:mb-4 prose-h3:text-xl prose-h3:mt-6 prose-h3:mb-3 prose-p:text-gray-200 prose-p:leading-relaxed prose-p:my-4 prose-p:text-base prose-strong:text-white prose-strong:font-semibold prose-ul:my-4 prose-ul:pl-6 prose-li:text-gray-200 prose-li:my-2 prose-ol:my-4 prose-ol:pl-6 prose-a:text-primary prose-a:no-underline hover:prose-a:underline"
                  />
                </div>

                {/* Tags and Share */}
                <div className="flex flex-wrap justify-between items-center mb-10 bg-maitech-800 p-6 rounded-lg border border-primary/10">
                  <div className="flex flex-wrap gap-2 mb-4 md:mb-0">
                    <h4 className="text-sm font-medium mr-2 flex items-center">Tags:</h4>
                    {tags.map((tag, index) => (
                      <Link 
                        key={index}
                        to={`/blog/tag/${tag.toLowerCase().replace(/ /g, '-')}`}
                        className="text-xs px-3 py-1 rounded-full bg-primary/10 text-primary hover:bg-primary/20 transition-colors"
                      >
                        {tag}
                      </Link>
                    ))}
                  </div>
                  
                  <ShareBlogPost title={title} url={pageUrl} />
                </div>

                <Separator className="my-10 bg-gray-800" />

                {/* Author Bio */}
                <BlogPostAuthor {...authorBio} />
              </article>

              {/* Related Posts */}
              <RelatedBlogPosts posts={blogPosts} currentPostId={post.id} />
            </div>
            
            {/* Sidebar */}
            <div className="lg:col-span-1">
              <div className="sticky top-24">
                <BlogSidebar 
                  popularPosts={popularPosts}
                  categories={categories}
                  tags={tags}
                />
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default BlogPost;
