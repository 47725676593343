import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { addBasicLighting } from '@/lib/threeUtils';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

// Updated tech stacks with logo paths
const techStacks = [
  { 
    name: 'React', 
    color: 0x61DAFB, 
    position: new THREE.Vector3(-4, 2, 0),
    logoPath: '/lovable-uploads/473c9c39-25e6-4a4a-a37f-3ee8a65681ed.png'
  },
  { 
    name: 'Node.js', 
    color: 0x68A063, 
    position: new THREE.Vector3(-2, -1, 2),
    logoPath: '/lovable-uploads/2a396334-8c9d-44e1-a79f-3cc0c82dc417.png' 
  },
  { 
    name: 'TypeScript', 
    color: 0x3178C6, 
    position: new THREE.Vector3(0, 0, -3),
    logoPath: '/lovable-uploads/db1c48a5-4b0e-423d-bbf3-c8240b87444d.png'
  },
  { 
    name: 'Three.js', 
    color: 0xFFFFFF, 
    position: new THREE.Vector3(2, 1, 1),
    logoPath: '/lovable-uploads/04b305ca-bbd2-4164-ac92-c50eec955c72.png' // Using Mai Tech logo
  },
  { 
    name: 'TailwindCSS', 
    color: 0x38BDF8, 
    position: new THREE.Vector3(4, -2, -1),
    logoPath: '/lovable-uploads/tailwindcss-logo.png'
  },
  { 
    name: 'Supabase', 
    color: 0x3ECF8E, 
    position: new THREE.Vector3(-3, -3, 0),
    logoPath: '/lovable-uploads/2853285e-bec0-416f-853a-01addd9e28b4.png' // PostgreSQL logo as fallback
  },
  { 
    name: 'Python', 
    color: 0xFFD43B, 
    position: new THREE.Vector3(3, 3, -2),
    logoPath: '/lovable-uploads/e5dcd499-58ae-4cee-991a-38fc1df9694b.png'
  },
  // Additional technologies to fill the expanded space
  { 
    name: 'AWS', 
    color: 0xFF9900, 
    position: new THREE.Vector3(-5, -2, -3),
    logoPath: '/lovable-uploads/e1166503-cec2-44ba-b520-ad71abba747f.png'
  },
  { 
    name: 'Docker', 
    color: 0x2496ED, 
    position: new THREE.Vector3(5, 0, -4),
    logoPath: '/lovable-uploads/dc680211-47fc-4b2b-a833-53be0a8cb1d3.png'
  },
  { 
    name: 'TensorFlow', 
    color: 0xFF6F00, 
    position: new THREE.Vector3(1, 4, -1),
    logoPath: '/lovable-uploads/ad567192-dc34-4373-b478-f286ccc7bcd1.png'
  },
  { 
    name: 'MongoDB', 
    color: 0x4DB33D, 
    position: new THREE.Vector3(-1, -4, 2),
    logoPath: '/lovable-uploads/26f163a5-d8b0-4007-a7b3-8efe06d82635.png'
  }
];

const TechStackScene = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const sceneInstanceRef = useRef<{
    cleanup: () => void;
  } | null>(null);
  const isCleanedUpRef = useRef(false);

  useEffect(() => {
    if (!containerRef.current) return;
    
    // Reset cleanup state
    isCleanedUpRef.current = false;

    // Scene initialization
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0x000000); // Pure black like Hero3D

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      60,
      containerRef.current.clientWidth / containerRef.current.clientHeight,
      0.1,
      1000
    );
    camera.position.z = 12; // Move camera back to see more planets

    // Renderer setup
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    rendererRef.current = renderer;
    renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    // Use basic tone mapping to preserve original colors like Hero3D
    renderer.toneMapping = THREE.NoToneMapping;
    renderer.toneMappingExposure = 1.0;
    containerRef.current.appendChild(renderer.domElement);

    // Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.05;
    controls.enableZoom = false;
    controls.autoRotate = true;
    controls.autoRotateSpeed = 0.5;
    
    // Adjust touch controls for better mobile experience
    controls.touches = {
      ONE: THREE.TOUCH.ROTATE,
      TWO: THREE.TOUCH.DOLLY_ROTATE
    };
    
    // Disable camera momentum to fix scrolling issues on mobile
    controls.enableDamping = window.innerWidth > 768; // Only enable damping on desktop

    // Lighting
    addBasicLighting(scene);
    
    // Add directional light
    const dirLight = new THREE.DirectionalLight(0xffffff, 1.2);
    dirLight.position.set(5, 5, 5);
    scene.add(dirLight);

    // Create stars background
    createStarsBackground(scene);

    // Create tech stack objects as floating logos (no planets)
    const techObjects: THREE.Object3D[] = [];
    const textureLoader = new THREE.TextureLoader();
    const logosRef: THREE.Sprite[] = [];

    techStacks.forEach((tech) => {
      // Load logo and create a sprite
      try {
        textureLoader.load(
          tech.logoPath,
          (texture) => {
            // Create sprite material with the logo
            const spriteMaterial = new THREE.SpriteMaterial({ 
              map: texture,
              transparent: true,
              alphaTest: 0.1,
            });
            
            const sprite = new THREE.Sprite(spriteMaterial);
            // Larger sprites since they're the main focus now
            sprite.scale.set(1.0, 1.0, 1.0);
            sprite.position.copy(tech.position);
            
            scene.add(sprite);
            techObjects.push(sprite);
            logosRef.push(sprite);
            
            // Add point light matching tech color for glow effect
            const pointLight = new THREE.PointLight(tech.color, 0.8, 5);
            pointLight.position.copy(tech.position);
            scene.add(pointLight);
          },
          undefined,
          (error) => {
            console.warn(`Error loading logo for ${tech.name}:`, error);
            
            // Fallback: Create a text label
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            if (ctx) {
              canvas.width = 256;
              canvas.height = 256;
              ctx.fillStyle = '#FFFFFF';
              ctx.font = 'bold 32px Arial';
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(tech.name, 128, 128);
              
              const texture = new THREE.CanvasTexture(canvas);
              const spriteMaterial = new THREE.SpriteMaterial({ 
                map: texture,
                transparent: true 
              });
              
              const sprite = new THREE.Sprite(spriteMaterial);
              sprite.scale.set(1.5, 0.45, 1.5);
              sprite.position.copy(tech.position);
              
              scene.add(sprite);
              techObjects.push(sprite);
              logosRef.push(sprite);
            }
          }
        );
      } catch (error) {
        console.warn(`Error creating logo for ${tech.name}:`, error);
      }
    });

    // Animation loop with logo rotation
    let frameId: number | null = null;
    const animate = () => {
      frameId = requestAnimationFrame(animate);

      // Rotate all logos
      logosRef.forEach(logo => {
        logo.rotation.z += 0.01;
      });
      
      // Update controls
      controls.update();

      // Render
      renderer.render(scene, camera);
    };

    animate();

    // Handle resize
    const handleResize = () => {
      if (!containerRef.current || !renderer) return;
      
      camera.aspect = containerRef.current.clientWidth / containerRef.current.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(containerRef.current.clientWidth, containerRef.current.clientHeight);
      
      // Update camera distance based on screen size
      if (window.innerWidth <= 768) {
        // Move camera further back on mobile to show more of the scene
        camera.position.z = Math.max(camera.position.z, 15);
        
        // Adjust controls for mobile
        if (controls) {
          controls.enableDamping = false;
          controls.rotateSpeed = 0.7; // Faster rotation on mobile
        }
      } else {
        // Reset to default position on desktop if needed
        if (camera.position.z > 12) {
          camera.position.z = 12;
        }
        
        // Adjust controls for desktop
        if (controls) {
          controls.enableDamping = true;
          controls.rotateSpeed = 0.5;
        }
      }
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    // Store cleanup function
    sceneInstanceRef.current = {
      cleanup: () => {
        // Prevent multiple cleanups
        if (isCleanedUpRef.current) return;
        isCleanedUpRef.current = true;
        
        if (frameId !== null) {
          cancelAnimationFrame(frameId);
        }
        
        if (containerRef.current && renderer.domElement && containerRef.current.contains(renderer.domElement)) {
          try {
            containerRef.current.removeChild(renderer.domElement);
          } catch (e) {
            console.warn('Error removing renderer DOM element:', e);
          }
        }
        
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
        
        // Dispose Three.js resources
        renderer.dispose();
        
        // Clear references
        rendererRef.current = null;
      }
    };

    return () => {
      if (sceneInstanceRef.current) {
        sceneInstanceRef.current.cleanup();
      }
    };
  }, []);
  
  // Create stars background
  const createStarsBackground = (scene: THREE.Scene) => {
    // Create stars
    const starGeometry = new THREE.BufferGeometry();
    const starMaterial = new THREE.PointsMaterial({
      color: 0xffffff,
      size: 0.03,
      transparent: true,
      opacity: 0.8,
    });

    const starVertices = [];
    const starCount = 2000;
    const starDistance = 40;

    for (let i = 0; i < starCount; i++) {
      const x = (Math.random() - 0.5) * starDistance;
      const y = (Math.random() - 0.5) * starDistance;
      const z = (Math.random() - 0.5) * starDistance;
      starVertices.push(x, y, z);
    }

    starGeometry.setAttribute('position', new THREE.Float32BufferAttribute(starVertices, 3));
    const stars = new THREE.Points(starGeometry, starMaterial);
    scene.add(stars);
  };

  return (
    <div 
      ref={containerRef} 
      className="w-full h-full absolute inset-0 touch-auto"
      aria-label="3D Universe Visualization"
    />
  );
};

export default TechStackScene; 