
import { useEffect } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useLanguage } from '@/contexts/LanguageContext';

export const LanguageRedirect = () => {
  const { language, setLanguage } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Detect browser language
    const detectLanguage = () => {
      const browserLang = navigator.language.toLowerCase();
      // Check if browser language starts with 'vi' for Vietnamese
      if (browserLang.startsWith('vi')) {
        return 'vi';
      }
      // Default to English
      return 'en';
    };

    // Only set detected language if no preference exists
    if (!localStorage.getItem('preferredLanguage')) {
      const detectedLang = detectLanguage();
      localStorage.setItem('preferredLanguage', detectedLang);
      setLanguage(detectedLang as 'en' | 'vi');
    }
  }, [setLanguage]);

  useEffect(() => {
    // Handle URL-based navigation when location changes
    if (location.pathname === '/') {
      // We're at the root, will be redirected by the return statement
      return;
    }

    const currentPathParts = location.pathname.split('/');
    const currentLang = currentPathParts[1];
    
    if ((currentLang === 'en' || currentLang === 'vi') && currentLang !== language) {
      // If URL has a language prefix that differs from context, update context
      setLanguage(currentLang as 'en' | 'vi');
    } else if (language && currentLang !== 'en' && currentLang !== 'vi') {
      // If URL has no language prefix, redirect to the language root
      navigate(`/${language}${location.pathname}`, { replace: true });
    }
  }, [language, location.pathname, navigate, setLanguage]);

  // Redirect to the appropriate language root
  return <Navigate to={`/${language || 'en'}`} replace />;
};

export default LanguageRedirect;
