
import { Brain, Code, Users, Building2 } from "lucide-react";
import CountUp from "react-countup";

const Stats = () => {
  const stats = [
    {
      icon: <Users className="w-8 h-8 text-primary" />,
      value: 10,
      label: "Users Across Our Apps",
      suffix: "k+",
    },
    {
      icon: <Code className="w-8 h-8 text-primary" />,
      value: 20,
      label: "Projects Completed",
      suffix: "+",
    },
    {
      icon: <Brain className="w-8 h-8 text-primary" />,
      value: 100000,
      label: "AI Interactions",
      suffix: "",
    },
    {
      icon: <Building2 className="w-8 h-8 text-primary" />,
      value: 5,
      label: "Years Experience",
      suffix: "",
    },
  ];

  return (
    <section className="py-20 bg-background/50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 gradient-text">Our Impact in Numbers</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-6 rounded-lg glass-card hover:border-primary transition-colors"
            >
              {stat.icon}
              <div className="mt-4 text-3xl font-bold text-foreground">
                <CountUp end={stat.value} duration={2.5} suffix={stat.suffix} />
              </div>
              <p className="mt-2 text-muted-foreground">{stat.label}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Stats;
